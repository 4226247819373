import { Component, OnInit, HostBinding } from "@angular/core";
import { animate, transition, trigger, style, query, stagger, animateChild } from "@angular/animations";
import { Router } from "@angular/router";
import { GAPIService } from "../core/gapi.service";
import { DataService, Accord } from "../data.service";
import { ToastrManager } from "ng6-toastr-notifications";

@Component({
  selector: "app-enter-question",
  templateUrl: "./enter-question.component.html",
  styleUrls: ["./enter-question.component.css"],
  animations: [
  trigger('enterQuestionAnimations', [
    transition(':enter', [
      query('.enterQuestion, form', [
        style({opacity: 0, transform: 'translateY(-100px)'}),
        stagger(200, [
          animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateY(0)" }))
        ])
      ])
    ]),
    transition(':leave', [
      // query('.static-text, form', [
        query('.enterQuestion', [
        style({ opacity: 0.5, transform: 'translateX(0%)' }),
        stagger(30, [
          animate('700ms ease-out', style({ transform: 'translateX(-170%)', opacity: 1}))
        ])
      ])
    ]),
  ])
  ]
})
export class EnterQuestionComponent implements OnInit {
  @HostBinding('@enterQuestionAnimations')

  public createRoomTransition: boolean = false;
  public question: string;
  public accord: Accord;
  public shortLink: string;
  public animateComp = false;
  public submitAnimate = "";

  constructor(
    private gapi: GAPIService,
    private router: Router,
    private data: DataService,
    private toastr: ToastrManager
  ) {}

  ngOnInit() {
    this.animateComp = false;
    this.data.accord.filter(a => a !== null).subscribe(accord => {
        this.accord = accord;
      });
    this.data.joinedAccordUser.filter(u => u !== null).subscribe(joinedUser => {});
    this.data.shortLink.filter(l => l != null).subscribe(link => {
      });
  }

  onSubmit(event) {
    if (event.keyCode == 13) {
      if (this.question) {
        this.createRoomTransition = true;
        const accord = new Accord();
        accord.question = this.question;
        this.data.create(accord);
      }
      else {
        this.toastr.errorToastr('Error!', 'Please enter your question to continue!',{timeOut: 500});
      }
    }
  }
  submitQuestion(question) {
    if (this.question) {
      this.createRoomTransition = true;
      const accord = new Accord();
      accord.question = this.question;
      this.data.create(accord);
    }
    else {
      this.toastr.errorToastr('Error!', 'Please enter your question to continue!',{timeOut: 500});
    }
  }
  limitCharacters(event) {
    if (event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
    }
  }
}
