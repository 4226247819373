<div class="room-member-options" style="width: 100%;">
  <form (ngSubmit)="enterSuggestion(); suggestionEntryForm.reset()" #suggestionEntryForm="ngForm">
    <input type="text" [maxlength]="140" class="suggestion-entry" [(ngModel)]="suggestion" #model="ngModel" (ngModelChange)="suggestion = $event"  (keyup)=" showTickIcon($event)" name="suggestion"
      placeholder="Suggest an idea and press enter" [focus]="myFocusTriggeringEventEmitter" required>
    <div [hidden]="model.valid || model.pristine" class="alert alert-danger">Please enter your idea to continue.</div>
    <button type="submit" class="hidden" [disabled]="!suggestionEntryForm.form.valid">Suggest</button>
    <div class="outer-from-pannel-check-icon" style="padding-left: 0px;" *ngIf="showCheckIcon2" [@IconsAnimation2]="'in'">
      <a  (click)="enterSuggestion()">
        <i class="fa fa-check" aria-hidden="true"></i>
      </a>
      <button type="submit" class="hidden"></button>
    </div>
  </form>
  
</div>
