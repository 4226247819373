import { ViewChild,Component,OnInit,Input,Output,EventEmitter} from '@angular/core';
import { storage } from 'firebase';
import { FileUploadService } from '../file-upload.service';
import { Accord, DataService } from '../data.service';
import { trigger, transition, animate, style, state, group } from '@angular/animations';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  animations: [
    trigger('FileIconsAnimation', [
      state('in', style({
        transform: 'translateY(-50)', opacity: 1
      })),
      transition(':enter', [
        style({ width: '*', transform: 'translateY(-50px)', opacity: 0 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*', transform: 'translateX(0px)' }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('1s 1000ms ease', style({ opacity: 1, width: '*' }))
        ])
      ])
    ])
  ]
})
export class FileUploadComponent implements OnInit {
  public accord: Accord;
  @Input()
  supportedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/jfif',
    'application/pdf',
    'application/zip'
  ];
  @Output() setUrl: EventEmitter<File> = new EventEmitter();
  @Output() setFileName = new EventEmitter();
  @Output() isUploading = new EventEmitter();
  @Output() uploadComplete = new EventEmitter();
  showUploading: boolean = false;
  cancelUpload: boolean = false;
  @ViewChild('fileInput') fileInput: any;
  public uploadProgress: number = 0;

  constructor(
    private data: DataService,
    private fileUpload: FileUploadService
  ) {}

  fileUploadError() {
    return this.fileUpload.errorMessage;
  }
  ngOnInit() {
    this.isUploading.emit(false);
    this.data.accord$.subscribe(accord => (this.accord = accord));
  }
  uploadCleared(event) {
    console.log(event);
    this.cancelUpload = true;
  }
  uploadFile(event) {
    const file = event.files[0];
    const uploadName = Math.floor(Date.now()).toString() + '--' + file.name;
    this.fileUpload
      .uploadFile(this.accord.roomId, uploadName, file)
      .subscribe(uploadTask => {
        this.setFileName.emit(file.name);
        this.fileUpload.lock();
        this.showUploading = true;
        this.isUploading.emit(this.showUploading);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress = Math.ceil(
              snapshot.bytesTransferred / snapshot.totalBytes * 100
            );
            this.uploadProgress = progress;
            if (this.cancelUpload) {
              this.cancelUpload = false;
              this.clearUpload();
            }
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          error => {
            // Handle unsuccessful uploads
            console.log(error);
            this.fileUpload.unlock();
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              this.setUrl.emit(downloadURL);
              this.isUploading.emit(this.showUploading);
              this.uploadComplete.emit(downloadURL);
              this.clearUpload();
              console.log('File available at', downloadURL);
            });
          }
        );
      });
  }
  clearUpload() {
    this.fileInput.clear();
    this.showUploading = false;
  }
}
