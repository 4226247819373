import { Component, HostBinding, OnInit, Input } from "@angular/core";
import { trigger, transition, animate, style, query, stagger, state, group
} from "@angular/animations";

import { AccordOption } from "../core/accord.service";
import { DataService, Accord, AccordState } from "../data.service";
import { Router } from "@angular/router";
import { UtilService } from "../core/util.service";
import { ToastrManager } from "ng6-toastr-notifications";

@Component({
  selector: "app-list-user-accords",
  templateUrl: "./list-user-accords.component.html",
  styleUrls: ["./list-user-accords.component.css"],
  animations: [
    trigger("listUserAnimations", [
      transition(":enter", [
        query(".panel, .historyWrapper", [
          style({ opacity: 0, transform: "translateY(-100px)" }),
          stagger(250, [
            animate(
              "1s 1000ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ]),
      transition(':leave', [
        query(".panel, .historyWrapper", [
          style({ opacity: 0.5, transform: 'translateX(0%)' }),
          stagger(100, [
            animate('400ms ease-out', style({ transform: 'translateX(-100%)', opacity: 1 }))
          ])
        ])
      ])
    ]),

    trigger("filterAnimations", [
      transition(":enter, * => 0, * => -1", []),
      transition(":increment", [
        query(
          ":enter",
          [
            style({ opacity: 0 }),
            stagger(250, [animate("0.5s 300ms ease-out", style({ opacity: 1 }))])
          ],
          { optional: true }
        )
      ]),
      transition(":decrement", [
        query(
          ":leave",
          [stagger(250, [animate("0.5s 300ms ease-out", style({ opacity: 0 }))])],
          { optional: true }
        )
      ])
    ])
  ]
})
export class ListUserAccordsComponent implements OnInit {
  @HostBinding("@listUserAnimations")
  listTotal = -1;

  @Input() accordsArray: Array<Accord>;
  accords: Array<Accord>;
  public hasAccords = false;
  public loaded = false;
  public animatePage = false;
  public dropDownValue = "ALL"
  public showAngleUpIcon = true;

  public searchKeyword: string;
  public AccordState = AccordState;
  public accordsCopy: Array<Accord>;
  public options: Array<any>;

  listUserAnimation = false;
  constructor(
    private data: DataService,
    private router: Router,
    private util: UtilService,
    private toastr: ToastrManager
  ) {}

  ngOnInit() {
    this.accords = this.accordsArray;
    this.accordsCopy = this.accords;
    this.listTotal = this.accords.length;
    if (this.accords.length > 0) {
      this.hasAccords = true;
    }

    this.loaded = true;
  }

  listUserAccords() {
    this.data.loadUserAccords().subscribe(accords => {
      if (accords != null) {
        this.accords = accords;
        this.accordsCopy = this.accords;
        this.listTotal = accords.length;

        if (accords.length > 0) {
          this.hasAccords = true;
          setTimeout(() => {
            this.accords.map(accord => {
              if (accord && accord.options) {
                accord.optionCount = Object.keys(accord.options).length;
              } else {
                accord.optionCount = 0;
              }
            });
            this.loaded = true;
            this.animatePage = true;
          }, 400);
        }
      }
    });
  }

  accordState(accord: Accord): string {
    if (accord.state === AccordState.Active) return "Active";
    if (accord.state === AccordState.Brainstorming) return "Brainstorming";
    if (accord.state === AccordState.Finished) return "Finished";
    if (accord.state === AccordState.ReadyForChoosing) return "Ready";
    return "Unknown State";
  }

  selectRoom(roomId: string, toOptions: boolean = false) {
    if (toOptions) return this.router.navigate(["room", roomId, "options"]);
    return this.router.navigate(["room", roomId]);
  }

  filterResults(FilterParam) {
    this.accords = this.accordsCopy;
    this.accords = this.accordsCopy.filter(
      accord => accord.state == FilterParam
    );
  }
  resetFilter() {
    this.searchKeyword = "";
    this.listUserAccords();
  }
  searchAccord(event) {
    if (event.keyCode == "13") {
      let searchRegx = new RegExp(this.searchKeyword.toLowerCase().trim());
      this.accords = this.accordsCopy;
      this.accords = this.accordsCopy.filter(accord =>
        accord.question.toLowerCase().match(searchRegx)
      );
      const newTotal = this.accords.length;
      if (this.listTotal !== newTotal) {
        this.listTotal = newTotal;
      } else if (!this.accords) {
        this.listTotal = -1;
      }
    }
  }

  deleteAccord(accord: Accord) {
    setTimeout(() => {
    this.data.delete(accord);
     }, 100);
    this.toastr.successToastr("Success!", "Accord Deleted!", {
       toastTimeout: '500',maxShown: (1),position: ('bottom-right') 
       });
       this.data.loadUserAccords().subscribe(accords => {
        if (accords != null) {
          this.accords = accords;
          if (accords.length > 0) {
              this.accords.map(accord => {
                if (accord && accord.options) {
                  accord.optionCount = Object.keys(accord.options).length;
                } else {
                  accord.optionCount = 0;
                }
              });
            this.loaded = true;
          }
        }
      });

  }

  // goToAccord(accord: Accord) {
  //   if (accord.state == 1) {
  //   this.router.navigate(["/room", accord.roomId]);
  //   return false;
  //   } else if(accord.state == 3) {
  //     let currentKey  = Object.keys(accord.choices);
  //     this.router.navigate(['/room',accord.roomId,'make-choices',currentKey[0]]);
  //   }
  //   return false;
  // }
  goToAccord(accord: Accord) {
    this.router.navigate(['/room', accord.roomId]);
    return false;
  }

  toggleOpenFinished(accord: Accord) {
    let newState = AccordState.Undefined;
    if (accord.state === AccordState.Finished) {
      let choiceHasBeenMade = false;
      if (accord.choices) {
        this.util.objectToArray(accord.choices).forEach(choice => {
          if (!choiceHasBeenMade) {
            if (choice.members) {
              choiceHasBeenMade = true;
            }
          }
        });
        if (choiceHasBeenMade) {
          newState = AccordState.Active;
        } else {
          newState = AccordState.Brainstorming;
        }
      } else {
        newState = AccordState.Brainstorming;
      }
    } else {
      newState = AccordState.Finished;
    }
    this.data.changeAccordState(accord, newState);
    return false;
  }

  showDuplicate(index) {
    let values = this.accords[index];
    let cloned = new Accord();
    cloned.link = this.accords[index].link;
    cloned.ownerId = this.accords[index].ownerId;
    cloned.question = "Copy of (" + this.accords[index].question + ")";
    cloned.roomId = this.accords[index].roomId;
    cloned.choices = this.accords[index].choices
      ? this.accords[index].choices
      : null;
    cloned.options = this.accords[index].options
      ? this.accords[index].options
      : null;
    cloned.createdOn = new Date().getTime();
    cloned.code = this.accords[index].code;
    cloned.state = 1;
    this.data.duplicateAccord(cloned).then(resp => {
      this.toastr.successToastr("Success!", "Accord has been duplicated!", {
        timeOut: 700
      });
      this.listUserAccords();
    });
  }

  updateOption(accord: Accord, key: string, opt: AccordOption) {
    this.data.setOption(accord, key, opt).then(() => {
      this.data.rebuildChoices(accord, true);
    });
  }

  changeIcon() {
    this.showAngleUpIcon = !this.showAngleUpIcon
  }

  toggleDropDownValue(index) {
   
    switch (index) {
      case 0: {
        this.dropDownValue = "ALL"
        break;
      }
      case 1: {
        this.dropDownValue = "BRAINSTORMING"
        break;
      }
      case 2: {
        this.dropDownValue = "ACTIVE"
        break;
      }
      case 3: {
        this.dropDownValue = "FINISHED"
        break;
      }
      default: {
        break;
      }
    }
  }
}
