<div class="alert alert-info" *ngIf="message">{{ message }}</div>
<div class="wrapper">
  <div class="profile">
    <!-- <div class="profile-image-holder"> -->
      <!-- User Avatar -->
      <!-- <div class="avatarDisplay"> -->
        <!-- <app-avatar-display></app-avatar-display>-->
        <!-- <img class="img-circle" src="../../assets/images/avatar.png" /> -->
      <!-- </div> -->
      <!-- Camera Icon -->
      <!-- <a class="camera-icon"> -->
        <!-- <i class="fa fa-camera" aria-hidden="true"></i> -->
      <!-- </a> -->
    <!-- </div> -->

    <!-- Form Input -->
    <div
      class="container profile-info"
       [ngClass]="{ backgroundBlur: profileSubmitTransition }"
    >
      <div class="col-xs-12">
        <form (submit)="updateDisplayProfile()">
          <div class="row trnstn_name">
            <div class="col-sm-3 col-xs-12 p45" >
              <label for="">Name</label>
            </div>
            <div class="col-sm-6 col-xs-12"
            [ngClass]="{ allignName: showName }">
              <p
                *ngIf="!editName"
                type="text"
                class="color-black"
                name="displayName"
                style="border: none"
              >
                {{ displayName }}
              </p>
              <input
                *ngIf="editName"
                type="text"
                class="border-bottom"
                [maxlength]="30"
                name="displayName"
                [(ngModel)]="displayName"
              />
            </div>
            <div class="col-sm-3 col-xs-12">
              <a class="link" (click)="changeEditMode('displayName')">Change</a>
            </div>
          </div>
          <div class="row m25 trnstn_email">
            <div class="col-sm-3 col-xs-12 p45">
              <label for="">E-mail</label>
            </div>
            <div class="col-sm-6 col-xs-12">
              <p
                *ngIf="!editEmail"
                type="text"
                class="color-black"
                name="email"
                style="border: none"
              >
                {{ email }}
              </p>
              <input
                *ngIf="editEmail"
                type="text"
                disabled="{{ emailFieldDisabled }}"
                class=""
                name="email"
                [(ngModel)]="email"
                style="border: none; width: 100%"
              />
            </div>
            <div class="col-sm-3 col-xs-12">
              <a class="link" (click)="changeEditMode('email')">Change</a>
            </div>
          </div>
          <div class="row m25 trnstn_password">
            <div class="col-sm-3 col-xs-12 p45">
              <label for="">Password</label>
            </div>
            <div class="col-sm-6 col-xs-12"
            [ngClass]="{ allignPassword: showPassword }">
              <p
                *ngIf="!editPassword"
                type="password"
                class="color-black"
                name="password"
                style="border: none; letter-spacing: 2px;"
                [(ngModel)]="staticPassword"
              >
                {{ staticPassword }}
              </p>
              <input
                *ngIf="editPassword"
                type="password"
                [maxlength]="15"
                
                class="border-bottom"
                name="password"
                [(ngModel)]="password"
                style="border: none; letter-spacing: 2px;"
              />
            </div>
            <div class="col-sm-3 col-xs-12">
              <a class="link" (click)="changeEditMode('password')">Change</a>
            </div>
          </div>
          <!-- <div class="row m25 trnstn_fb">
            <div class="col-sm-3 col-xs-12 p45">
              <label for="">Facebook</label>
            </div>
            <div class="col-sm-6 col-xs-12">
              <input
                type="text"
                disabled="true"
                class=""
                name="facebookEmail"
                [(ngModel)]="facebookEmail"
                style="border: none; width: 100%;"
              />
            </div>
            <div class="col-sm-3 col-xs-12"></div>
          </div> -->
          <!-- <div class="row m25 trnstn_google">
            <div class="col-sm-3 col-xs-12 p45">
              <label for="">Google +</label>
            </div>
            <div class="col-sm-6 col-xs-12">
              <input
                type="text"
                disabled="true"
                class=""
                name="googleEmail"
                [(ngModel)]="googleEmail"
                style="border: none; width: 100%;"
              />
            </div> -->
            <!-- <div class="col-sm-3 col-xs-12"></div> -->
          <!-- </div> -->
        </form>
      </div>
    </div>
    <!-- Action Button -->
    <div>
      <p-confirmDialog
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        key="displayName"
      ></p-confirmDialog>
      <button
        [ngClass]="{ profileTransit: profileSubmitTransition }"
        class="saveButton"
        (click)="updateDisplayProfile()"
      >
        Save
      </button>
    </div>
  </div>
</div>
<app-loader *ngIf="profileSubmitTransition"></app-loader>

<!-- <div class="delete-profile-button">
  <app-delete-profile></app-delete-profile>
</div> -->
