<nav class="custom-navbar navbar navbar-fixed-top" [@expandAnimation]="navBarAnimation">
  <div class="nav">
    <nav class="navbar navbar-default navbar-static-top">
      <div class="container">
        <div class="row">
          <!-- <a href="#" class="logo-icon col-xs-2" (click)="redirectRoute()">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a> -->
          <div class="navbar-header col-xs-8 col-xs-offset-2">
             <a class="navbar-brand" href="/">
              <img src="/assets/images/logo-1x.png"> 
            </a> 
          </div>
          <!-- <div class="navbar-header pull-right navbar-authentication">
          <app-navbar-authentication></app-navbar-authentication>
        </div>  -->
          <div class="imageIcon col-xs-2">
             <img class="img-circle" [matMenuTriggerFor]="menu" src="../../assets/images/avatar.png">
             <mat-menu #menu = "matMenu">
              <button routerLink="/profile" class = "button-wrap" mat-menu-item>Profile</button>
              <button routerLink="/enter-question" class = "button-wrap" mat-menu-item>New Accord</button>
              <button disabled class = "button-wrap" mat-menu-item>Setting</button>
              <button routerLink="/history" class = "button-wrap" mat-menu-item>History</button>
              <button disabled class = "button-wrap" mat-menu-item>Upgrade</button>
              <button (click)="logout()" mat-menu-item>Sign Out</button>
           </mat-menu>
          </div>
        </div>
      </div>
    </nav>
  </div>
</nav>