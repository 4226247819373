import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
@Injectable()
export class EmbedlyService {
  private api_key = 'd5fd23333c0f4465b940c6508eb9a1f9';
  private oembedRoot = 'https://api.embedly.com/1/oembed';
  constructor(private http: Http) { }
  get(url: string) {
    return this.http.get(this.oembedRoot + '?key=' + this.api_key + '&url=' + encodeURIComponent(url));
  }

}
