import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http'; 
import { MatMenuModule, MatButtonModule, MatInputModule } from '@angular/material'
import 'hammerjs';

import { ChartModule } from 'angular2-chartjs';

import { AngularFireModule } from 'angularfire2';
import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ng6-toastr-notifications';
import { ClipboardModule } from 'ngx-clipboard';
// import { Ng2FileDropModule } from 'ng2-file-drop';

import { ValuesPipe } from './core/values.pipe';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { NavbarAuthenticationComponent } from './navbar-authentication/navbar-authentication.component';
import { SocialButtonsComponent } from './social-buttons/social-buttons.component';
import { TermsComponent } from './terms/terms.component';
import { SalesComponent } from './sales/sales.component';
import { SampleLinksComponent } from './sample-links/sample-links.component';
import { EnterQuestionComponent } from './enter-question/enter-question.component';
import { RoomComponent } from './room/room.component';
import { RoomOptionsComponent } from './room-options/room-options.component';
import { LoginComponent } from './login/login.component';
import { AnonymousLoginComponent } from './anonymous-login/anonymous-login.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { SignupComponent } from './signup/signup.component';
// import { UserinfoEditorComponent } from './userinfo-editor/userinfo-editor.component';
// import { PasswordEditorComponent } from './password-editor/password-editor.component';
import { ProfileComponent } from './profile/profile.component';
import { AvatarDisplayComponent } from './avatar-display/avatar-display.component';
import { AvatarChangeComponent } from './avatar-change/avatar-change.component';
import { LinkFacebookComponent } from './link-facebook/link-facebook.component';
import { LinkGoogleComponent } from './link-google/link-google.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { NavbarAuthenticationLoginComponent } from './navbar-authentication-login/navbar-authentication-login.component';
import { NavbarAuthenticationLogoutComponent } from './navbar-authentication-logout/navbar-authentication-logout.component';
import { RoomStatusComponent } from './room-status/room-status.component';
import { QuestionDisplayComponent } from './question-display/question-display.component';
import { RoomLinkDisplayComponent } from './room-link-display/room-link-display.component';
import { SmartLinkComponent } from './smart-link/smart-link.component';
import { SmartLinkAddComponent } from './smart-link/smart-link-add/smart-link-add.component';
import { SmartLinkListComponent } from './smart-link/smart-link-list/smart-link-list.component';
import { RoomSuggestionListComponent } from './room-suggestion-list/room-suggestion-list.component';
import { RoomOwnerOptionEntryComponent } from './room-owner-option-entry/room-owner-option-entry.component';
import { RoomSuggestionCountHeadingComponent } from './room-suggestion-count-heading/room-suggestion-count-heading.component';
import { RoomMemberSuggestionListComponent } from './room-member-suggestion-list/room-member-suggestion-list.component';
import { RoomMemberSuggestionEntryComponent } from './room-member-suggestion-entry/room-member-suggestion-entry.component';
import { RoomOptionListComponent } from './room-option-list/room-option-list.component';
// tslint:disable-next-line:max-line-length
import { RoomOwnerSuggestionAcceptOrRejectComponent } from './room-owner-suggestion-accept-or-reject/room-owner-suggestion-accept-or-reject.component';
import { RoomLockComponent } from './room-lock/room-lock.component';
import { SignoutComponent } from './signout/signout.component';
import { LoaderComponent } from './loader/loader.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireDatabaseModule} from 'angularfire2/database'

import {
  AccordService,
  GAPIService,
  UtilService,
  EmbedlyService,
  ValuesAccordOptionsPipe
} from './core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CountPropertiesPipe } from './core/count-properties.pipe';
import { RoomMakeChoicesComponent } from './room-make-choices/room-make-choices.component';
import { RoomMakeChoicesChooserComponent } from './room-make-choices-chooser/room-make-choices-chooser.component';

import {
  LocalStorageService,
  LocalStorageModule
} from 'angular-2-local-storage';

import { MomentModule } from 'angular2-moment';

import { AnimationService } from 'css-animator';
import { RoomResultsComponent } from './room-results/room-results.component';
import { RoomResultsGraphComponent } from './room-results-graph/room-results-graph.component';
import { RoomResultsFilterComponent } from './room-results-filter/room-results-filter.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { ListUserAccordsComponent } from './list-user-accords/list-user-accords.component';

import { AppRoutingModule } from './app.routing';
import { DataService } from './data.service';
import { RoomStartChoicesComponent } from './room-start-choices/room-start-choices.component';
import { HistoryComponent } from './history/history.component';
import { NotFoundComponent } from './not-found/not-found.component';

/* Prime NG */
import { ConfirmDialogModule,ConfirmationService,DialogModule,FileUploadModule,
        ProgressBarModule,SlideMenuModule,MenuItem } from 'primeng/primeng';
import { FileUploadService } from './file-upload.service';
import { RoomOwnerOptionDeleteComponent } from './room-owner-option-delete/room-owner-option-delete.component';
import { FocusDirective } from './focus.directive';
import { AuthService } from './auth.service';
import { RoomExportCsvComponent } from './room-export-csv/room-export-csv.component';
import { EmailRecoverPasswordComponent } from './email-recover-password/email-recover-password.component';
import { DeleteProfileComponent } from './delete-profile/delete-profile.component';

import { AngularFireAuthModule } from 'angularfire2/auth';

// import { GAPIService } from './core/gapi.service';
// import { UtilService } from './core/util.service';

export const firebaseConfig = {
  apiKey: 'AIzaSyDORhBr53n3iQxxsB7al6xmUXKPVkLXK84',
  authDomain: 'accordapp.firebaseapp.com',
  databaseURL: 'https://accordapp.firebaseio.com',
  storageBucket: 'firebase-accordapp.appspot.com',
  messagingSenderId: '882986329243'
};

@NgModule({
  declarations: [
    ValuesPipe,
    ValuesAccordOptionsPipe,
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    NavbarMenuComponent,
    NavbarAuthenticationComponent,
    SocialButtonsComponent,
    TermsComponent,
    SalesComponent,
    SampleLinksComponent,
    EnterQuestionComponent,
    RoomComponent,
    RoomOptionsComponent,
    LoginComponent,
    AnonymousLoginComponent,
    GoogleLoginComponent,
    FacebookLoginComponent,
    EmailLoginComponent,
    SignupComponent,
    // UserinfoEditorComponent,
    // PasswordEditorComponent,
    ProfileComponent,
    AvatarDisplayComponent,
    AvatarChangeComponent,
    LinkFacebookComponent,
    LinkGoogleComponent,
    CopyrightComponent,
    LoginComponent,
    NavbarAuthenticationLoginComponent,
    NavbarAuthenticationLogoutComponent,
    RoomStatusComponent,
    QuestionDisplayComponent,
    RoomLinkDisplayComponent,
    SmartLinkComponent,
    SmartLinkAddComponent,
    SmartLinkListComponent,
    RoomSuggestionListComponent,
    RoomOwnerOptionEntryComponent,
    RoomSuggestionCountHeadingComponent,
    RoomMemberSuggestionListComponent,
    RoomMemberSuggestionEntryComponent,
    RoomOptionListComponent,
    RoomOwnerSuggestionAcceptOrRejectComponent,
    RoomLockComponent,
    SignoutComponent,
    HeaderComponent,
    FooterComponent,
    FileUploadComponent,
    CountPropertiesPipe,
    RoomMakeChoicesComponent,
    RoomMakeChoicesChooserComponent,
    RoomResultsComponent,
    RoomResultsGraphComponent,
    RoomResultsFilterComponent,
    PlanSelectionComponent,
    ListUserAccordsComponent,
    RoomStartChoicesComponent,
    HistoryComponent,
    NotFoundComponent,
    RoomOwnerOptionDeleteComponent,
    FocusDirective,
    RoomExportCsvComponent,
    EmailRecoverPasswordComponent,
    DeleteProfileComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    // Ng2FileDropModule,
    LocalStorageModule.forRoot({
      prefix: 'app',
      storageType: 'localStorage'
    }),
    ChartModule,
    MomentModule,
    DialogModule,
    FileUploadModule,
    ProgressBarModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    SlideMenuModule,
    MatMenuModule, 
    MatButtonModule,
    MatInputModule,
    LightboxModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule
  ],
  providers: [
    AccordService,
    DataService,
    GAPIService,
    UtilService,
    EmbedlyService,
    LocalStorageService,
    AnimationService,
    FileUploadService,
    AuthService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
