
<div class="row no-records" *ngIf="loaded && !hasAccords">
  <h3>You have no existing accords.</h3>
</div>

<div class="historyWrapper" *ngIf="loaded && hasAccords">
  <div class="container">
    <div class="review-previous-questions">
      <div class="search-wrapper">
        <div class="allign-content padding-0 col-sm-10 col-xs-9">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input
            class="search-description border-btm"
            [maxlength]="40"
            name="enter-question"
            [(ngModel)]="searchKeyword"
            (keypress)="searchAccord($event)"
            type="text"
            placeholder="Search discussions"
          />
        </div>
        <div class="col-sm-2 col-xs-3">
          <button (click)="resetFilter()" class="button-Search">Reset</button>
        </div>
      </div>

      <div class="Menu-Item col-sm-12" >
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          mat-position-mode="target-right target"
          id="dropDownButton"
          (click) = "changeIcon()"
        >
        {{dropDownValue}}
          <i class="fa fa-angle-up" *ngIf="showAngleUpIcon" aria-hidden="true" id="user-icon"></i>
          <i class="fa fa-angle-down" *ngIf="!showAngleUpIcon" aria-hidden="true" id="user-icon"></i>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="listUserAccords()"  (click)="toggleDropDownValue(0)"  (click) = "changeIcon()" class="button-wrap" mat-menu-item>
            ALL
          </button>
          <button
            (click)="filterResults('1')"
            (click)="toggleDropDownValue(1)"
            (click) = "changeIcon()"
            class="button-wrap"
            mat-menu-item
          >
            BRAINSTORMING
          </button>
          <button
            (click)="filterResults('3')"
            (click)="toggleDropDownValue(2)"
            (click) = "changeIcon()"
            class="button-wrap"
            mat-menu-item
          >
            ACTIVE
          </button>
          <button
            (click)="filterResults('4')"
            (click)="toggleDropDownValue(3)"
            (click) = "changeIcon()"
            class="button-wrap"
            mat-menu-item
          >
            FINISHED
          </button>
        </mat-menu>
      </div>
          <div class="list-suggestions"  [@filterAnimations]="listTotal" >
        <div
          class="panel custom-panel clearfix"
          *ngFor="let accord of accords ; let i = index" 
        >
          <div class="col-sm-6 col-md-8 col-xs-12" (click)="goToAccord(accord)">
            <h4 class="question-history">{{ accord.question }}</h4>
            <div class="wrap-Button-status">
              <button
                class="button-Brainstorming"
                *ngIf="accord.state == AccordState.Brainstorming"
              >
                {{ accordState(accord) }}
              </button>
              <button
                class="button-Finished"
                *ngIf="accord.state == AccordState.Finished"
              >
                {{ accordState(accord) }}
              </button>
              <button
                class="button-Active"
                *ngIf="accord.state == AccordState.Active"
              >
                {{ accordState(accord) }}
              </button>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xs-12 status-action-btn">
            <div class="row">
              <div class="col-sm-12 col-xs-9 wrap-button-list">
                <button class="button-duplicate" (click)="showDuplicate(i)">
                  DUPLICATE
                </button>
                <!-- Export CSV Component -->
                <button
                  [ngClass]="
                    accord.optionCount == 0
                      ? 'buttonExportDisabled'
                      : 'buttonExportHistory'
                  "
                  [disabled]="accord.optionCount == 0"
                >
                  <app-room-export-csv
                    *ngIf="accord.optionCount > 0"
                    [room]="accord"
                  ></app-room-export-csv>
                </button>
                <button
                  *ngIf="accord.state < AccordState.Finished"
                  (click)="toggleOpenFinished(accord)"
                  class="button-finish"
                >
                  FINISH
                </button>
                <button
                  *ngIf="accord.state == AccordState.Finished"
                  href
                  (click)="toggleOpenFinished(accord)"
                  class="button-open"
                >
                  OPEN
                </button>
                <button (click)="deleteAccord(accord)" class="button-delete">
                  DELETE
                </button>
              </div>
              <div class="col-sm-12 col-xs-3 text-right timeago">
                {{ accord.createdOn | amTimeAgo }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
