import { Component, OnInit, Input } from "@angular/core";
import { Accord, DataService } from "../data.service";

@Component({
  selector: "app-room-suggestion-count-heading",
  templateUrl: "./room-suggestion-count-heading.component.html",
  styleUrls: ["./room-suggestion-count-heading.component.css"]
})
export class RoomSuggestionCountHeadingComponent implements OnInit {
  public accord: Accord;
  icontrue = true;

  constructor(private data: DataService) {}

  showIcon() {
    this.icontrue = !this.icontrue;
  }
  ngOnInit() {
    this.data.accord$.subscribe(accord => (this.accord = accord));
  }
}
