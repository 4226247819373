
import { of as observableOf, zip as observableZip,  Observable ,  Subscription } from 'rxjs';
import { catchError, map, first} from 'rxjs/operators';
import { DataService } from '../data.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
         Router} from '@angular/router';

@Injectable()
export class IsOwnerOfRoomGuard implements CanActivate {
  id: any;
  constructor(private data: DataService) {}
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.id = next.params.id;
    const user = this.data.accordUser;
    const accord = this.data.get(this.id);
    // changed from combineLatest
    return Observable.zip(accord, user)
      .map(res => {
        console.log(res);
        return res[0].ownerId === res[1].uid;
      }).catch(err => {
        console.log(err)
        return observableOf(false);
      })
      
  }
}
