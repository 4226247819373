
import {filter} from 'rxjs/operators';
import { AccordState, AccordUser, DataService } from "../data.service";
import { LocalStorageService } from "angular-2-local-storage";
import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  AfterViewInit,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "app-question-display",
  templateUrl: "./question-display.component.html",
  styleUrls: ["./question-display.component.css"]
})
export class QuestionDisplayComponent implements OnInit, AfterViewInit {
  accord: any;
  public myFocusTriggeringEventEmitter = new EventEmitter<boolean>();
  @Input() user: AccordUser;
  @ViewChildren("editInput") editInput: any;

  public descriptionWasClicked: boolean = false;
  public ownerEdit: boolean = false;
  constructor(
    private data: DataService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.data.accord.pipe(
      filter(accord => accord != null))
      .subscribe(accord => {
        this.accord = accord;
        let pixelToChar = 12;
        let defaultLineCount = 1;
        this.accord.rowSize =
          Math.ceil(
            this.accord.question.length /
              Math.floor((window.innerWidth * 0.7) / pixelToChar)
          ) + defaultLineCount;
      });
  }
  ngAfterViewInit(): void {
    if (this.editInput) {
      this.editInput.changes.subscribe(changeEvent => {
        if (changeEvent.first && changeEvent.first.nativeElement) {
          changeEvent.first.nativeElement.focus();
        }
      });
    }
  }
  ngAfterViewChecked() {
    this.myFocusTriggeringEventEmitter.emit(true);
  }

  descriptionClicked() {
    if (this.accord.state <= AccordState.ReadyForChoosing) {
      if (this.user && this.accord.ownerId === this.user.uid) {
        this.ownerEdit = true;
        this.descriptionWasClicked = true;
      }
    }
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      this.updateQuestion(event);
    }
  }
  updateQuestion($event) {
    this.data.setQuestion(this.accord, this.accord.question);
    this.descriptionWasClicked = false;
  }
}
