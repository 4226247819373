
import {filter} from 'rxjs/operators';
import { AccordOption } from "../core/accord.service";
import { UtilService } from "../core/util.service";
import { Component, OnInit, Input, ViewChildren, AfterViewInit,  ViewEncapsulation } from "@angular/core";
import { Accord, DataService, AccordUser } from "../data.service";
import { trigger, transition, animate, style, state, group } from '@angular/animations';

@Component({
  selector: "app-room-suggestion-list",
  templateUrl: "./room-suggestion-list.component.html",
  styleUrls: ["./room-suggestion-list.component.css"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('SuggestionIconsAnimation', [
      state('in', style({
        transform: 'translateX(-50)', opacity: 1
      })),
      transition(':enter', [
        style({ width: '*', transform: 'translateX(-50px)', opacity: 0 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*', transform: 'translateX(0px)' }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('1s 1000ms ease', style({ opacity: 1, width: '*' }))
        ])
      ])
    ])
  ]
})
export class RoomSuggestionListComponent implements OnInit, AfterViewInit {
  @ViewChildren("descInput") descInput: any;
  @Input() accord: any;
  @Input() uid: string;

  userIsAccordOwner: boolean;
  accordUser: AccordUser;
  public formValid: boolean = false;
  public editingValue;

  constructor(private data: DataService, private util: UtilService) {}

  ngOnInit() {
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.pipe(
      filter(user => user !== null))
      .subscribe(user => {
        this.accordUser = user;
        this.userIsAccordOwner = this.accordUser.uid === this.accord.ownerId;
      });
  }

  ngAfterViewInit() {
    if (this.descInput) {
      this.descInput.changes.subscribe(changeEvent => {
        if (changeEvent.first && changeEvent.first.nativeElement) {
          changeEvent.first.nativeElement.focus();
        }
      });
    }
  }

  clickedIfDescriptionIsClickable(
    suggestion: { descriptionWasClicked: boolean },
    description
  ) {
    suggestion.descriptionWasClicked = true;
    this.editingValue = description;
  }

  onKeydown(event, index, suggestion) {
    if (event.key === "Escape") {
      this.resetSuggestion(index, suggestion);
    }
  }

  userIsSuggestionOwner(suggestion: AccordOption) {
    return this.accordUser.uid === suggestion.member;
  }
  setUrl(accord: Accord, key: string, suggestion: AccordOption, event) {
    console.log(event);
    suggestion.url = event;
    suggestion["showUpload"] = null;
    this.data.setSuggestion(accord, key, suggestion);
  }
  isUploading(event) {
    console.log(event);
  }
  deleteSuggestion(key: string) {
    this.data.deleteSuggestion(this.accord, key);
    return false;
  }
  updateSuggestion(
    accord: Accord,
    key: string,
    suggestion: AccordOption,
    event: Event,
    description
  ) {
    suggestion["descriptionWasClicked"] = null;
    this.data.setSuggestion(accord, key, suggestion).then(() => {
      this.data.rebuildChoices(accord, true);
    });
  }

  resetSuggestion(index, suggestion: { descriptionWasClicked: boolean }) {
    this.accord.suggestions[
      Object.keys(this.accord.suggestions)[index]
    ].description = this.editingValue;
    suggestion.descriptionWasClicked = false;
  }

  showActionBar(suggestion: AccordOption) {
    if (this.userIsSuggestionOwner(suggestion)) {
      return true;
    }
    return this.userIsAccordOwner;
  }
  clearUrl(accord: Accord, key: string, suggestion: AccordOption, event) {
    suggestion.url = "";
    //this.data.setOption(accord, key,suggestion);
  }
}
