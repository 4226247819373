import { animate, transition, trigger,query,stagger, style } from "@angular/animations";
import { filter } from 'rxjs/operators';
import {combineLatest as observableCombineLatest, combineLatest, observable } from 'rxjs';
import { Accord, DataService, AccordUser } from "../data.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Component, OnInit, HostBinding } from "@angular/core";

@Component({
  selector: "app-room-make-choices",
  templateUrl: "./room-make-choices.component.html",
  styleUrls: ["./room-make-choices.component.css"],
  animations: [
    trigger('RmakeChoices', [
      transition(':enter', [
        query('.room-make-choices-chooser', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('0.7s 300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateY(0px)" }))
          ])
        ])
      ]),
      transition(":leave", [
        query('.room-make-choices-chooser', [
        style({ transform: "translateX(0%)", opacity: 1 }),
        stagger(30, [
        animate('250ms 500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateX(-100%)" }))
      ])
      ])
    ])
    ])
    ]
})
export class RoomMakeChoicesComponent implements OnInit {
  // @HostBinding('@RmakeChoices')
  public animatePage = true;
  static_value = -1;

  public roomId: string;
  public accord: Accord;
  public accordUser: AccordUser;
  public uid: string;
  showContent;

  constructor(private route: ActivatedRoute, private data: DataService) {
    this.data.accordUser.pipe(filter(u => u !== null)).subscribe(user => {
        this.uid = user.uid;
        this.route.params.subscribe((params: Params) => {
          this.roomId = params["id"];
          this.data.get(this.roomId);
        });
      });
    this.data.accord.pipe(filter(a => a !== null)).subscribe(accord => {
        this.accord = accord;
      });
  }

  ngOnInit() {
    observableCombineLatest(this.data.accordUser, this.route.params).subscribe(
      results => {
        this.accordUser = results[0];
      }
    );
  }
}
