<!-- <div class="row">
   <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
    <img *ngIf="accord.link" src="https://api.qrserver.com/v1/create-qr-code/?size=100x100&amp;data={{accord.link}}&amp;color=00d0ff&amp;bgcolor=f3f4f5">
  </div>
  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
    <a class="link-room">{{accord?.link}}</a>
     <br>
    <p class="copy">Ctrl-C to copy</p>
  </div>
</div> -->

<div class="link-room col-md-6 col-sm-7 col-xs-10">
  <a #inputTarget>{{ accord?.link }}</a>
</div>
<div class="col-md-6 col-sm-5 col-xs-2 clip-board">
  <button class="button-Copy" (click)="copyToClipboard()" ngxClipboard 
  [cbContent]="accord.link" >
    Copy
  </button>
</div>
