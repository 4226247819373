import { AccordOption } from './accord.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'values' })
export class ValuesPipe implements PipeTransform {
  transform(value: any, keyName = '_key', sort = false): Object[] {
    let keyArr: any[] = Object.keys(value),
      dataArr = [];

    keyArr.forEach((key: any) => {
      if (keyName) {
        value[key][keyName] = key;
      }
      dataArr.push(value[key]);
    });

    if (sort) {
      dataArr.sort((a: Object, b: Object): number => {
        return a[keyName] > b[keyName] ? 1 : -1;
      });
    }
    return dataArr;
  }
}

@Pipe({ name: 'valuesAccordOptions' })
export class ValuesAccordOptionsPipe implements PipeTransform {
  transform(value: any, keyName = '_key', sort = false): AccordOption[] {
    let keyArr: any[] = Object.keys(value),
      dataArr = [];

    keyArr.forEach((key: any) => {
      if (keyName) {
        value[key][keyName] = key;
      }
      dataArr.push(value[key]);
    });

    if (sort) {
      dataArr.sort((a: Object, b: Object): number => {
        return a[keyName] > b[keyName] ? 1 : -1;
      });
    }
    return dataArr;
  }
}

