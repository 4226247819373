import { Component, OnInit, HostBinding } from '@angular/core';
import { DataService, Accord } from "../data.service";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  accords: Array<Accord>;
  loaded = false;
  constructor(private data: DataService) { }

   ngOnInit() {
    this.listUserAccords();
  }
  listUserAccords() {
    this.data.loadUserAccords().subscribe(accords => {
      if (accords != null) {
        this.accords = accords;
        if (accords.length > 0) {
            this.accords.map(accord => {
              if (accord && accord.options) {
                accord.optionCount = Object.keys(accord.options).length;
              } else {
                accord.optionCount = 0;
              }
            });
          this.loaded = true;
        }
      }
    });
  }
}
