import { Component, HostBinding } from "@angular/core";

import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { Location } from "@angular/common";
import { AngularFireAuth } from "angularfire2/auth";
import { slideInAnimation } from "./router.animation";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: "app-root",
  animations: [slideInAnimation],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {

  @HostBinding('@.disabled')
  public animationsDisabled = false;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }
  
  public ShowNav: boolean = true;
  public mainWrapper;
  public innerWidth: any;
  public showFooter: boolean = true;
  authState: any = null;
  public user = firebase.auth().currentUser;

  constructor(
    private route: ActivatedRoute,
    private af: AngularFireAuth,
    private router: Router,
    private location: Location,
  ) {
    af.authState.subscribe((userState: any) => {
      if (
        userState &&
        userState.providerData &&
        userState.email == null
      ) {
        this.user = userState;
        this.user.updateEmail(userState.providerData[0].email);
      }
      this.authState = userState;
      this.user = userState;
    });
  }

  ngDoCheck() {
    this.ShowNav = true;
    if (this.location.path() == "/login") {
      this.ShowNav = false;
      this.showFooter = false;
    }
    if (this.location.path() == "/signup") {
      this.ShowNav = false;
      this.showFooter = false;
    }
    if (
      !(this.location.path() == "/login") &&
      !(this.location.path() == "/signup")
    ) {
      this.showFooter = true;
    }
    if (this.location.path() == "/recover-password") {
      this.ShowNav = false;
      this.showFooter = false;
    }
    if ((this.location.path().includes("/make-choices")) && window.innerWidth <= 480) {
      document.getElementById("main-component").style.height="98%";
    }
    if (new RegExp("filter").test(this.router.url) && window.innerWidth < 767) {
      this.showFooter = false;
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
