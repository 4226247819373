import { AccordUser, Accord, DataService, AccordState } from '../data.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class IsChooseableGuard implements CanActivate {
  constructor(
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // changed from Obserable.combineLatest
    return Observable.combineLatest(this.data.get(next.params.id), this.data.accordUser)
      .first() // I don't think I need to hang onto the accord in the guard
      .map(results => {
        const accord: Accord = results[0];
        const user: AccordUser = results[1];
        const choiceKeys = accord.choices ? Object.keys(accord.choices) : [];
        const choicesLength = choiceKeys.length;
        if (choicesLength === 0) {
          return false;
        }
        const upperBound = choiceKeys.length - 1;
        const lowerBound = 0;
        const currentChoice = choiceKeys[0];
        if (accord.state === AccordState.Brainstorming) {
          if (accord.ownerId === user.uid) {
            if (choicesLength > 0) {
              this.data.setAccordState(accord, AccordState.ReadyForChoosing);
            }
          }
        }
        this.router.navigate(['/room',accord.roomId,'make-choices',currentChoice]);
        return false;
      });
  }
}
