<div class="row" *ngIf="choice">
  <div class="question-display col-sm-12 headline-text">
        {{ accord.question }}
  </div>

  <div class="clearfix make-choices-images">
      <!-- <div class="clearfix make-choices-images" > -->
    <div class="col-xs-6">
      <!-- <div *ngIf="choice.option1.url" class="panel">
      <a [href]="choice.option1.url" target="_blank">Download</a>
    </div> -->
      <div
        id="option1"
        class="content-wrapper cursor-pointer"
        [ngClass]="{selected : choice.option1.selected }"
        (click)="choose(choice, choice.option1)"
      >
        <img
          *ngIf="choice.option1.url"
          [src]="choice.option1.url"
        />
        <h4 [ngClass]="{ panelWithoutImage: !choice.option1.url }">
          {{ choice.option1.description }}
        </h4>
        <!-- <a *ngIf="choice.option1.url" [href]="choice.option1.url" target="_blank">Download</a> -->
      </div>
    </div>
    <div class="col-xs-6">
      <!-- <div *ngIf="choice.option2.url" class="panel">
      <a [href]="choice.option2.url" target="_blank">Download</a>
    </div> -->
      <div
        id="option2"
        class="content-wrapper cursor-pointer"
        [ngClass]="{ selected : choice.option2.selected }"
        (click)="choose(choice, choice.option2)"
        
      >
        <img
          *ngIf="choice.option2.url"
          [src]="choice.option2.url"
          class="oembed-image"
        />
        <h4 [ngClass]="{ panelWithoutImage: !choice.option2.url }">
          {{ choice.option2.description }}
        </h4>
        <!-- <a *ngIf="choice.option2.url" [href]="choice.option1.url" target="_blank">Download</a> -->
      </div>
    </div>
  </div>
</div>

  <!-- Percentage Bar -->
  <div class="col-sm-12 progress-status">
    <div class="percentage text-right" [style.width]="percent() + '%'">
      <p>{{ percentage | number: "1.0-0" }}% completed</p>
    </div>
    <div class="progressbar-background">
    <div class="progressBar"  [style.width]="percent() + '%'"></div>
    </div>
  </div>
  <!--Preview Results-->
  <div class="col-sm-12" (click)="previewResults()"> 
    <a class="resultsLink"
     >
      I just want to see the results</a
    >
  </div>

  <!--Next and Previous section-->

  <div class="col-xs-6" >
    <!-- Previous -->
    <a *ngIf="previousChoice"
      class="previousLink"
      href
      [routerLink]="[
        '/room',
        this.accord.roomId,
        'make-choices',
        previousChoice.choiceId
      ]"
    >
      <i class="glyphicon glyphicon-chevron-left"></i> Previous</a
    >
  </div>

  <!-- Next -->
  <div
    class="col-xs-6 text-right"
    style="margin-bottom: 10px;"
    *ngIf="nextChoice"
  >
    <a
      class="nextLink"
      href
      [routerLink]="[
        '/room',
        this.accord.roomId,
        'make-choices',
        nextChoice.choiceId
      ]"
    >
      <i class="glyphicon glyphicon-chevron-right"></i> Next</a
    >
  </div>
<!-- </div> -->
