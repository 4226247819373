
import { animate, transition, trigger,query,stagger, style } from "@angular/animations";
import { filter} from 'rxjs/operators';
import { Accord, DataService } from '../data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-room-results',
  templateUrl: './room-results.component.html',
  styleUrls: ['./room-results.component.css'],
  animations: [
    trigger('RoomResultsAnimations', [
      transition(":enter", [
        query(".question-display, .showResults, .need-narrow, .export-csv", [
          style({ opacity: 0, transform: "translateY(-100px)" }),
          stagger(200, [
            animate(
              "1s 500ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ], { optional: true })
      ]),
      transition(':leave', [
        query(".container", [
          style({ opacity: 0.8, transform: 'translateX(0%)' }),
          stagger(100, [
            animate('550ms', style({ top: '-100%' ,transform: 'translateX(-100%)', opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
    ]
})
export class RoomResultsComponent implements OnInit {
  @HostBinding('@RoomResultsAnimations')
  static_value = -1;

  public roomId: string;
  public accord: Accord;
  public uid: string;
  public showFilter: boolean = false;
  showContent = false;

  constructor( public route: ActivatedRoute, public data: DataService) {}

  ngOnInit() {
    this.showContent = true;
    this.data.accordUser.pipe(filter(u => u != null)).subscribe((accordUser) => {
      this.uid = accordUser.uid;
      this.route.params
        .subscribe((params: Params) => {
          this.roomId = params['id'];
          this.data.get(this.roomId);
        });
    });
    this.data.accord.subscribe(accord => {
      this.accord = accord;
    });
  }

}
