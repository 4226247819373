<div *ngIf="!descriptionWasClicked" class="viewmode">
  <p (click)="descriptionClicked()">{{accord.question}}</p>
</div>
<div *ngIf="ownerEdit  && descriptionWasClicked" class="editmode">
  <form (ngSubmit)="updateQuestion($event);" #questionForm="ngForm">
    <div class="row">
      <div class="col-sm-11 col-xs-10">
        <textarea type="text" (keydown)="onKeydown($event)" [maxlength]="140" cols = "20" rows = "2" class="edit-question-display" [(ngModel)]="accord.question" #model="ngModel" (ngModelChange)="accord.question = $event"
          name="question" placeholder="Edit the question" [focus]="myFocusTriggeringEventEmitter" required #editInput></textarea>
        <div [hidden]="model.valid || model.pristine" class="custom-alert alert alert-danger">Please enter your question to continue.</div>
      </div>
      <div class="col-sm-1 col-xs-2 checkIcon">
        <a href="" (click)="updateQuestion($event); false">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a>
        <button type="submit" class="hidden"></button>
      </div>
    </div>
  </form>
</div>
