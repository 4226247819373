import { Component, OnInit, Input } from "@angular/core";
import { Accord, DataService } from "../data.service";
import { UtilService } from "app/core";
import { ExportToCsv } from "export-to-csv";
import { Location } from "@angular/common";
import * as moment from "moment";
import { ToastrManager } from 'ng6-toastr-notifications';
import "rxjs/Rx";

@Component({
  selector: "app-room-export-csv",
  templateUrl: "./room-export-csv.component.html",
  styleUrls: ["./room-export-csv.component.css"]
})
export class RoomExportCsvComponent implements OnInit {
  public accord: Accord;
  public exported: Boolean = false;
  public exportedMessage: string = "";
  @Input("room") room: Accord;
  displayName;
  choiceArr = [];
  constructor(
    private util: UtilService,
    private location: Location,
    private toastr: ToastrManager
  ) {}

  ngOnInit() {}
  listFilter(arr: any, search: string, key: any) {
    arr.forEach(objectkey => {
      if (objectkey[key] == search) {
        return objectkey["displayName"];
      }
    });
  }
  exportCSV() {
    let date = new Date(this.room.createdOn);
    const format = moment(date).format("YYYY, DD MMM hh:mm:ss a ZZ");

    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: [
        "accord id",
        "accord question label",
        "accord_owner_id",
        "accord_owner_name",
        "accord created on date/time",
        "choice id",
        "choice order",
        "date/time of choice made",
        "user id",
        "user display name",
        "option chosen id",
        "option chosen label",
        "option chosen attachment download URL",
        "option not chosen id",
        "option not chosen label",
        "option not chosen attachment download URL"
      ],
      showTitle: true,
      title: "Report",
      useBom: false,
      removeNewLines: true,
      keys: [
        "accord_id",
        "accord_question",
        "accord_owner_id",
        "accord_owner_name",
        "accord_created_on_date_time",
        "choice_id",
        "choice_order",
        "date_time_of_choice made",
        "user_id",
        "user_display_name",
        "option_chosen_id",
        "option_chosen_label",
        "option_chosen_attachmentdownload_URL",
        "option_not_chosen_id",
        "option_not_chosen_label",
        "option_not_chosen_attachmentdownload_URL"
      ]
    };
    let export_array = [];
    let no_user = "Nil";
    const csvExporter = new ExportToCsv(options);
    let members = this.util.objectToArray(this.room.members);
    let choices = this.util.objectToArray(this.room.choices);
    choices.forEach((choice, i) => {
      let data = {
        accord_id: this.room.roomId,
        accord_question: this.room.question,
        accord_owner_id: this.room.ownerId,
        accord_owner_name: this.room.members[this.room.ownerId].displayName,
        accord_created_on_date_time: format
      };
      data["choice_id"] = choice._key;
      data["choice_order"] = i;
      data["date_time_of_choice_made"] = "Coming soon";
      if (choice.members && Object.keys(choice.members).length > 0) {
        Object.keys(choice.members).forEach((ch_member, j) => {
          let choice_member = choice.members[ch_member];
          data["user_id"] = choice_member._key;
          data["user_display_name"] = choice_member.displayName;
          data["option_chosen_id"] = choice_member.option._key;
          data["option_chosen_label"] = choice_member.option.description;
          data["option_chosen_attachmentdownload_URL"] =
            choice_member.option.url;
          let not_selected;
          if (choice_member.option._key == choice.option1._key) {
            not_selected = choice.option2;
          } else {
            not_selected = choice.option1;
          }
          data["option_not_chosen_id"] = not_selected._key;
          data["option_not_chosen_label"] = not_selected.description;
          data["option_not_chosen_attachmentdownload_URL"] = not_selected.url;
          export_array.push({ ...data });
        });
      } else {
        data["user_id"] = no_user;
        data["user_display_name"] = no_user;
        data["option_chosen_id"] = no_user;
        data["option_chosen_label"] = no_user;
        data["option_chosen_attachmentdownload_URL"] = no_user;
        data["option_not_chosen_id"] = no_user;
        data["option_not_chosen_label"] = no_user;
        data["option_not_chosen_attachmentdownload_URL"] = no_user;
        export_array.push({ ...data });
      }
    });
    if (export_array.length == 0) {
      this.toastr.errorToastr("Error!", "Please create your accord first before export results", {
        timeOut: 3000
      });
      return false;
    } else {
      csvExporter.generateCsv(export_array);
    }

    this.toastr.successToastr(
      "Success!",
      "The room " + this.room.question + " has been exported",
      { timeOut: 2000 }
    );
    // this.exportedMessage =
    //   "The room " + this.room.question + " has been exported.";
    // this.exported = true;
  }
}
