
import { filter, take, map} from 'rxjs/operators';
import { UtilService } from './core/util.service';
import { GAPIService } from './core/gapi.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Injectable()
export class DataService {
  private _choicePushed: BehaviorSubject<AccordChoiceChooser>;
  private currentUserId: string;
  private currentDisplayName: string;
  private _uid: BehaviorSubject<string>;
  private _displayName: BehaviorSubject<string>;
  public facebookLoginEmail: string;
  private _accord: BehaviorSubject<Accord>;
  private _accordUser: BehaviorSubject<AccordUser>;
  private _joinedAccordUser: BehaviorSubject<AccordUser>;
  private _shortLink: BehaviorSubject<string>;
  private _newOptionAdded: BehaviorSubject<AccordOption>;
  private _newSuggestionAdded: BehaviorSubject<AccordOption>;
  private _rebuiltChoices: BehaviorSubject<boolean>;
  private _userAccords: BehaviorSubject<Array<Accord>>;
  private _optionDeleted: BehaviorSubject<string>;
  private items: any;
  private promise: any;
  private user = firebase.auth().currentUser;

  get displayName() {
    return this._displayName.asObservable();
  }

  get uid() {
    return this._uid.asObservable();
  }
  get accord() {
    return this._accord.asObservable();
  }
  get accord$() {
    return this.accord.filter(accord => accord !== null);
  }
  get accordUser() {
    return this._accordUser.asObservable();
  }
  currentAuth(): Promise<AccordUser> {
    return this.af.authState.pipe(
      filter(x => x != null),
      take(1),
      map(auth => {
        const user = new AccordUser();
        user.displayName = auth.displayName;
        return user;
      }),)
      .toPromise();
  }
  get joinedAccordUser() {
    return this._joinedAccordUser.asObservable();
  }
  get shortLink() {
    return this._shortLink.asObservable();
  }
  get newOptionAdded() {
    return this._newOptionAdded.asObservable();
  }
  get newSuggestionAdded() {
    return this._newSuggestionAdded.asObservable();
  }
  get rebuiltChoices() {
    return this._rebuiltChoices.asObservable();
  }
  get choicePushed() {
    return this._choicePushed.asObservable();
  }
  get userAccords() {
    return this._userAccords.asObservable();
  }
  get optionDeleted() {
    return this._optionDeleted.asObservable();
  }
  constructor(
    private af: AngularFireAuth,
    private db: AngularFireDatabase,
    private gapi: GAPIService,
    private util: UtilService,
    private router: Router
  ) {
    this._uid = new BehaviorSubject<string>('');
    this._displayName = new BehaviorSubject<string>('');
    this._accord = new BehaviorSubject<Accord>(null);
    this._joinedAccordUser = new BehaviorSubject<AccordUser>(null);
    this._shortLink = new BehaviorSubject<string>(null);
    this._accordUser = new BehaviorSubject<AccordUser>(null);
    this._newOptionAdded = new BehaviorSubject<AccordOption>(null);
    this._newSuggestionAdded = new BehaviorSubject<AccordOption>(null);
    this._rebuiltChoices = new BehaviorSubject<boolean>(null);
    this._choicePushed = new BehaviorSubject<AccordChoiceChooser>(null);
    this._userAccords = new BehaviorSubject<Array<Accord>>(null);
    this._optionDeleted = new BehaviorSubject<string>(null);

    this.af.authState.
      filter(a => a !== null)
      .subscribe(auth => {
        this.currentUserId = auth.uid;
        this.currentDisplayName = auth.displayName;
        const accordUser = new AccordUser();
        accordUser.displayName = this.currentDisplayName;
        accordUser.uid = this.currentUserId;
        this._accordUser.next(accordUser);
        this._uid.next(auth.uid);
        this._displayName.next(auth.displayName);
      });
  }

  get isLoggedIn() {
    if (this.currentUserId && !this.currentDisplayName) {
      this.currentDisplayName = 'Anonymous Member';
    }
    return this.currentDisplayName && this.currentUserId;
  }
  get(accordId) {
    return this.db
      .object(`/accord/accords/${accordId}`).valueChanges()
      .map((accord: any) => {
        this._accord.next(accord);
        return accord;
      });
  }
 
  create(accord: Accord) {
    if (this.isLoggedIn) {
      if (accord.ownerId == null) accord.ownerId = this.currentUserId;
      accord.state = AccordState.Brainstorming;
      accord.createdOn = Date.now();
      return this.db
        .list(`/accord/accords`)
        .push(accord)
        .then(pushQuestionResult => {
          const roomId = pushQuestionResult.key;
          return roomId;
        })
        .then(roomId => {
          accord.roomId = roomId;
          this.update(accord).then(() => {
            this._accord.next(accord);
            return this.db
              .object(
                `/accord/users/${this.currentUserId}/rooms/${accord.roomId}`
              )
              .set(accord)
              .then(() => {
                return this.join(
                  roomId,
                  this.currentUserId,
                  this.currentDisplayName
                );
              })
              .then(joined => {
                this.setShortLink(accord);
              });
          });
        });
    }
  }

  duplicateAccord(accord: Accord) {
    if (this.isLoggedIn) {
      if (accord.ownerId == null) accord.ownerId = this.currentUserId;
      accord.state = AccordState.Brainstorming;
      accord.createdOn = Date.now();
      return this.db
        .list(`/accord/accords`)
        .push(accord)
        .then(pushQuestionResult => {
          const roomId = pushQuestionResult.key;
          return roomId;
        })
        .then(roomId => {
          accord.roomId = roomId;
          this.update(accord).then(() => {
            this._accord.next(accord);
            return this.db
              .object(
                `/accord/users/${this.currentUserId}/rooms/${accord.roomId}`
              )
              .set(accord)
              .then(() => {
                return this.join(
                  roomId,
                  this.currentUserId,
                  this.currentDisplayName
                );
              })
              .then(joined => {
                this.duplicateShortLink(accord);
              });
          });
        });
    }
  }

  delete(accord: Accord) {
    if (this.isLoggedIn) {
      const roomId = accord.roomId;
      if (accord.ownerId === this.currentUserId) {
        this.db
          .list(`/accord/accords/${roomId}`)
          .remove()
          .then(() => {
            this.db
              .object(`/accord/users/${this.currentUserId}/rooms/${roomId}`)
              .remove();
          });
      }
    }
  }

  update(accord: Accord) {
    if (this.isLoggedIn) {
      if (this.currentUserId !== accord.ownerId)
        throw Error('Unable to update accord.');
      return this.db
        .object(`/accord/accords/${accord.roomId}`)
        .update(accord);
    }
  }

  loadUserAccords() {
    return this.db
      .list(`/accord/users/${this.currentUserId}/rooms`).valueChanges().pipe(
      map((accords: Array<Accord>) => {
        accords.forEach((accord: any) => {
          this.db.object(`/accord/accords/${accord.roomId}`).valueChanges().subscribe((roomAccord : any) => {
              Object.assign(accord, roomAccord);
            });
        });
        this._userAccords.next(accords);
        return accords;
      }));
  }

  setAccordState(accord: Accord, state: AccordState) {
    if (state == null) state = AccordState.Brainstorming;
    console.log(state = AccordState.Brainstorming)
    this.db.object(`/accord/accords/${accord.roomId}`)
      .update({ state: state }).then(response => {
      }).catch(err => {
        console.log(err,"Error")
      });
  }

  setAccordStateChooser(accord: Accord, state: AccordState) {
    this.db.object(`/accord/accords/${accord.roomId}`)
      .update({ state: state }).then(response => {
      }).catch(err => {
        console.log(err,"Error")
      });
  }

  changeAccordState(accord: Accord, state: AccordState) {
    if (state == null) state = AccordState.Brainstorming;
    this.db.object(`/accord/accords/${accord.roomId}`)
      .update({ state: state }).then(response => {
      }).catch(err => {
        console.log(err,"Error")
      });
  }

  join(roomId: string, uid: string, displayName: string): Promise<AccordUser> {
    const user = new AccordUser();
    Object.assign(user, { uid: uid, displayName: displayName });
    return this.db.object(`/accord/accords/${roomId}/members/${uid}`)
      .valueChanges()
      .first()
      .toPromise()
      .then((currentValue: any) => {
        // if (!currentValue.uid || currentValue.displayName !== displayName) {
          if (currentValue == null) {
          const objRef = `/accord/accords/${roomId}/members/${uid}`;
          return this.db
            .object(objRef)
            .set(user)
            .then(() => {
              this._joinedAccordUser.next(user);
              return user;
            });
        } else {
          return currentValue;
        }
      });
  }

  setQuestion(accord: Accord, question: string) {
    if (this.currentUserId === accord.ownerId) {
      return this.db.object(`/accord/accords/${accord.roomId}/question`)
        .set(question);
    }
  }
  pushSuggestion(accord: Accord, suggestion: AccordOption) {
    return this.db
      .list(`/accord/accords/${accord.roomId}/suggestions`)
      .push(suggestion)
      .then(results => {
        this._newSuggestionAdded.next(suggestion);
      });
  }
  setSuggestion(accord: Accord, key: string, suggestion: AccordOption) {
    if (this.isLoggedIn) {
      return this.db
        .object(`/accord/accords/${accord.roomId}/suggestions/${key}`)
        .set(suggestion);
    }
  }
  deleteSuggestion(accord: Accord, key: string) {
    return this.db
      .object(`/accord/accords/${accord.roomId}/suggestions/${key}`)
      .remove();
  }
  pushOption(accord: Accord, option: AccordOption) {
    if (this.isLoggedIn) {
      return this.db
        .list(`/accord/accords/${accord.roomId}/options`)
        .push(option)
        .then(results => {
          this._newOptionAdded.next(option);
        });
    }
  }
  setOption(accord: Accord, key: string, option: AccordOption) {
    if (this.isLoggedIn) {
      return this.db
        .object(`/accord/accords/${accord.roomId}/options/${key}`)
        .set(option);
    }
  }
  deleteOption(accord: Accord, key: string) {
    return this.db
      .object(`/accord/accords/${accord.roomId}/options/${key}`)
      .remove()
      .then(() => {
        this._optionDeleted.next(key);
      });
  }

  pushChoice(accord: Accord, choice: AccordChoiceChooser, option: AccordOption) {
    this.currentDisplayName = this.af.auth.currentUser.displayName;
    return this.db
      .object('/accord/accords/' +accord.roomId +
          '/choices/' + choice.choiceId +
          '/members/' + this.currentUserId)
      .set({
        option,
        displayName: this.currentDisplayName,
        updatedOn: Date.now()
      })
      .then(() => {
        this._choicePushed.next(choice);
      });
  }

  rebuildChoices(accord: Accord, randomizeOrder: boolean = false) {
    if (this.isLoggedIn && accord.ownerId === this.currentUserId) {
      let choices = Array<AccordChoice>();
      const potentialChoices = this.util.objectToArray(accord.options); // new Array<AccordOption>();
      potentialChoices.forEach((val, index) => {
        potentialChoices.forEach((val2, index2) => {
          const choice = new AccordChoice();
          if (index2 > index) {
            choice.option1 = val;
            choice.option2 = val2;
            choices.push(choice);
          }
        });
      });
      if (randomizeOrder) choices = this.util.shuffle(choices);

      const listRef = this.db.list(
        `/accord/accords/${accord.roomId}/choices`
      );
      return listRef.remove().then(() => {
        choices.forEach(choice => {
          listRef.push(choice);
        });
        this._rebuiltChoices.next(true);
      });
    }
  }

  setShortLink(accord) {
       const link = window.location.host.includes("localhost") ? `http://${window.location.host}/room/${accord.roomId}` : `https://${window.location.host}/room/${accord.roomId}`;
       this.gapi.GetLink(link).subscribe(linkResult => {
       return this.db
         .object(`/accord/accords/${accord.roomId}`)
         .update({ code: accord.roomId, link: linkResult.link })
         .then(updateResults => {
           return this.db
             .object(`/accord/links/${accord.roomId}`)
             .set({
               roomKey: accord.roomId,
               results: { code: accord.roomId, link: linkResult.link }
             })
             .then(() => {
              this.router.navigate(["room", accord.roomId]);
               return this._shortLink.next(linkResult.link);
             });
         });
       });
  }

  duplicateShortLink(accord) {
    const link = window.location.host.includes("localhost") ? `http://${window.location.host}/room/${accord.roomId}` : `https://${window.location.host}/room/${accord.roomId}`;
    this.gapi.GetLink(link).subscribe(linkResult => {
    return this.db
      .object(`/accord/accords/${accord.roomId}`)
      .update({ code: accord.roomId, link: linkResult.link })
      .then(updateResults => {
        return this.db
          .object(`/accord/links/${accord.roomId}`)
          .set({
            roomKey: accord.roomId,
            results: { code: accord.roomId, link: linkResult.link }
          })
          .then(() => {
          });
      });
    });
}
  updateUserProfile(profile: Profile) {
    // todo: I can't figure out a good way to remove password from profile, so I am mapping to an anonymous object
    if (this.isLoggedIn) {
      return this.db
        .object(`/accord/users/${this.currentUserId}/profile`)
        .set({ firstName: profile.firstName })
        .then(() => true);
    }
  }
  setDisplayProfile(displayName) {
    var user = firebase.auth().currentUser;
    return user.updateProfile({
        displayName: displayName,
        photoURL: '/images/anonymous.jpg'
      })
      .then(() => {
        this._accordUser.next(user);
      }).catch((err) => {
        console.log(err,"Error")
      });
  }

  membersWithChoices(accord) {
    const members = this.util.objectToArray(accord.members);
    const choices = this.util.objectToArray(accord.choices);
    members.forEach((value, index) => {
      choices.forEach((choice, choiceIndex) => {
        if (choice.members) {
          members.forEach((memberChoice, memberChoiceIndex) => {
            if (!value.hasChoice && memberChoice._key === value._key) {
              value.hasChoice = true;
            }
          });
        }
      });
    });
    // sort by name
    return this.util
      .filterArray(this.util.objectToArray(accord.members), { hasChoice: true })
      .sort((a, b) => {
        if (b.name === undefined) {
          return 1;
        }
        if (a.name === undefined) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
  }
}

export class Profile {
  public firstName: string;
  public lastName: string;
}
export class Accord {
  public roomId: string;
  public question: string;
  public ownerId: string;
  public createdOn: number;
  public options: AngularFireList<AccordOption>; // Array of AccordOption[]
  public code: string;
  public optionCount: any;
  public link: string;
  public members: any;
  public choices: AngularFireList<AccordChoice>; // Array of AccordChoice[]
  // public isLocked: boolean;
  // public readyForChoices: boolean;
  // public isClosed: boolean;
  public state: AccordState;

  public suggestions: AngularFireList<AccordOption>; // Array of AccordOption[]
}

export enum AccordState {
  Undefined,
  Brainstorming,
  ReadyForChoosing,
  Active,
  Finished
}

export class AccordUser {
  displayName: string;
  uid: string;
}

export class AccordChoice {
  members: AccordUser[];
  option1: AccordOption;
  option2: AccordOption;
}

export class AccordOption {
  description: string;
  member: string;
  url: string;
  data: any;
}

export class AccordChoiceChooser extends AccordChoice {
  public choiceId: string;
}
