<h3
  class="suggestions-heading"
  (click)="showIcon(icontrue)"
  *ngIf="accord.suggestions"
>
  Suggestions {{ accord.suggestions | countProperties }}
  <i
    class="fa fa-angle-up cursor-pointer"
    aria-hidden="true"
    id="user-icon"
    *ngIf="icontrue"
  ></i>
  <i class="fa fa-angle-down cursor-pointer" *ngIf="!icontrue" aria-hidden="true"></i>
</h3>
<h3 class="suggestions-heading" *ngIf="!accord.suggestions">No Suggestions</h3>
