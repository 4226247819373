import { Accord, DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Observable } from "rxjs";

@Component({
  selector: 'app-room-start-choices',
  templateUrl: './room-start-choices.component.html',
  styleUrls: ['./room-start-choices.component.css']
})
export class RoomStartChoicesComponent implements OnInit {

  constructor() {
    console.log('Start choices...');
    // this.route.params.subscribe(params => {
    //   Observable.combineLatest(this.data.get(params.id), this.data.accordUser).map(results => {
    //     const accord = results[0];
    //     const user = results[1];
    //     this.currentChoice = params['current'];
    //     this.choiceKeys = Object.keys(accord.choices);
    //     this.choicesLength = this.choiceKeys.length;
    //     const upperBound = this.choiceKeys.length - 1;
    //     const lowerBound = 0;
    //     this.currentChoice = this.choiceKeys[0];
    //     this.zone.run(() => this.router.navigate(['/room', accord.roomId, 'make-choices', this.currentChoice])
    //       .then(result => console.log('Start Choices navigate result', result)));
    //   });
    // });
  }

  ngOnInit() {
  }

}
