import { Accord, DataService } from "../data.service";
import { Component, OnInit, Input } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: "app-room-link-display",
  templateUrl: "./room-link-display.component.html",
  styleUrls: ["./room-link-display.component.css"]
})
export class RoomLinkDisplayComponent implements OnInit {
  public accord: Accord;
  constructor(
    private data: DataService,
    private _clipboardService: ClipboardService,
    private toastr: ToastrManager
  ) {}
  textToCopy;
  ngOnInit() {
    this.data.accord$.subscribe(accord => {
      this.accord = accord;
    });
  }

  copyToClipboard() {
    let statics;
    this.textToCopy = this.accord.link;
    this._clipboardService.copyFromContent(this.textToCopy, statics);
    this.toastr.successToastr('', 'Copy to clipboard!',{timeOut: 800});
  }
}
