<!-- <div class="forget-password-wrapper" [@flyInOut]="'in'"> -->
  <div class="forget-password-wrapper">
  <div *ngIf="message" class="alert alert-info">{{ message }}</div>
   <div *ngIf="!emailSent" class="email-form">
      <div class="email-form">
      <div class="animate-text">
     <h4 >Enter email address</h4>
     </div>
    <!--Email Form-->
    <form role="form" class="email-recover-password" (submit)="recoverPassword()">
      <div class="row">
        <div class="col-md-9 col-sm-8 col-xs-12 email-animate">
          <input
            type="email"
            placeholder="Email"
            [(ngModel)]="emailAddress"
            name="emailAddress"
            class="input input-text"
          />
        </div>
        <!-- Recover Password Button-->
        <div class="col-md-3 col-sm-4 col-xs-6 recoverpass-animate">
          <button type="submit" name="recoverPassword" class="recover-button">
            Recover Password
          </button>
          <!--End of Recover Password Button -->
        </div>

        <!-- Return Link-->
      <div class="return-link col-md-12 col-sm-12 col-xs-12">
        <a (click)=redirectRoute()>Return to login</a>
        <!-- End of Return Link-->
      </div>
      </div>
      <!--End Email Form-->
    </form>

  </div>
</div>
</div>
