import { Accord, DataService } from '../data.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-lock',
  templateUrl: './room-lock.component.html',
  styleUrls: ['./room-lock.component.css']
})
export class RoomLockComponent implements OnInit {
  public accord: Accord;
  public uid: string;

  constructor(
    private router: Router,
    private data: DataService,
  ) {
    this.data.accordUser.filter(user => user !== null).subscribe(user => {
      this.uid = user.uid;
    });
    this.data.accord$.subscribe(accord => {
      this.accord = accord;
    });
  }

  ngOnInit() {}

  navigateStartChoices() {
    console.log('Navigating');
    this.router
      .navigate(['room', this.accord.roomId, 'start-choices'], {
        replaceUrl: true
      }).then(result => console.log('navigate result', result));
  }
}
