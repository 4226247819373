<footer class="footer" >
  <div class="container">
  <div class="row">
    <div class="col-sm-4 col-xs-12">
      <app-terms></app-terms>
    </div>
    <div class="col-sm-4 col-xs-12 ng-binding text-center">
      <app-copyright></app-copyright>
    </div>
    <div class="col-sm-4 col-xs-12 text-right">
      <app-social-buttons></app-social-buttons>
    </div>
  </div>
  </div>
</footer>
