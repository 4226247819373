<div class="panel customRoomSuggestion" *ngFor="let suggestion of accord.suggestions | valuesAccordOptions ; let i=index">
    <div class="panel-body">
    <div [ngClass]="{'col-sm-12': !showActionBar(suggestion), 'col-sm-10 col-xs-12': showActionBar(suggestion)}">
        <div class="row">
          <!-- <div [ngClass]="{'': suggestion.url}">
          <div *ngIf="suggestion.url">
            <div class="oembed-card">
              <img *ngIf="isImage(suggestion.url)" [src]="suggestion.url" class="img img-responsive oembed-image" />
            </div>
          </div>
        </div>  -->
            <div *ngIf="userIsSuggestionOwner(suggestion); else nonOwnersTemplate" class="row">
              <div class="col-sm-11" style="margin-left: 12px">
                <div class="option-info" *ngIf="!suggestion.descriptionWasClicked; else updateSuggestionForm" >
            <h3 (click)="clickedIfDescriptionIsClickable(suggestion, suggestion.description)"> 
                  {{suggestion.description}} </h3>
                  <div class="user-img" *ngIf="suggestion.url">
                      <img [src]="suggestion.url" class="suggestion-image" />
               </div>
                  <div class="showTrash-icon">
                      <a href="" class="col-xs-1 outer-from-pannel-delete" style="padding-left: 0px;" (click)="deleteSuggestion(suggestion._key); false;">
                        <i class="fa fa-trash"></i>
                      </a>
                  </div> 
                </div>
              
                <ng-template #updateSuggestionForm>
                  <div class="description-input">
                    <form (ngSubmit)="updateSuggestion(accord, suggestion._key, suggestion, $event);"
                      #suggestionEntryForm="ngForm">
                      <!-- <div class="row"> -->
                        <div class="col-sm-11 col-xs-11">
                          <input #descInput type="text" class="update-suggestion" (keydown)="onKeydown($event, i, suggestion)" [(ngModel)]="suggestion.description"
                            #model="ngModel" (ngModelChange)="suggestion.description = $event"
                            name="suggestion-description" placeholder="Type an option and press enter" required>
                          <div [hidden]="model.valid || model.pristine" class="alert alert-danger">Please enter your
                            option to continue.</div>
                        </div>
  
                        <div class="col-sm-1 col-xs-1 plus-icon" style="padding-left: 0px;">
                            <div class="showUpload-icons">
                              <div *ngIf="!suggestion.url">
                                <a href="" *ngIf="!suggestion.showUpload; else closeUploadTemplate"
                                (click)="suggestion.showUpload = !suggestion.showUpload; false;">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </a>
                              <ng-template #closeUploadTemplate>
                                  <a href="" (click)="suggestion.showUpload = !suggestion.showUpload; false;">
                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                  </a>
                                </ng-template>
                                </div>
                            </div>
                          </div>

                          <div class="" *ngIf="suggestion.url">
                          <div class="col-xs-12 col-md-12">
                          <div class="user-img">
                              <img *ngIf="suggestion.url" [src]="suggestion.url" class="suggestion-image" />
                            <div class="upload-icon-cross" (click)="clearUrl(accord, suggestion._key, suggestion, $event); false">
                              <i class=" fa fa-times" aria-hidden="true"></i>
                            </div> 
                              </div>
                          </div>
                        </div>
  
                        <!-- <div class="checkTrue col-xs-1 ct-outer-from-pannel" style="padding-left: 0px;">
                          <a href="" (click)="updateSuggestion(accord,suggestion._key, suggestion, $event); false">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </a>
                          <button type="submit" class="hidden"></button>
                        </div> -->
                    <!-- </div> -->
                      </form>
                    <div class="image-upload image-file-uploading" *ngIf="suggestion.showUpload">
                      <div class="col-xs-12">
                          <div class="upload-component1" >
                            <app-file-upload (setUrl)="setUrl(accord, suggestion._key,suggestion,$event)"
                          (isUploading)="isUploading($event)"></app-file-upload>
                      </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <ng-template #nonOwnersTemplate>
              <div class="suggestion-description col-xs-12">
                <h3>{{suggestion.description}}</h3>
               <div class="user-img" *ngIf="suggestion.url">
               <img [src]="suggestion.url" class="suggestion-image">
              </div> 
            </div>
            </ng-template>
           
        </div>
    </div>
    <div class="suggestion-panel col-sm-2 col-xs-12" *ngIf="showActionBar(suggestion)">
      <div *ngIf="userIsAccordOwner; else suggestionOwnerDelete">
        <app-room-owner-suggestion-accept-or-reject [key]="suggestion._key" [suggestion]="suggestion">
        </app-room-owner-suggestion-accept-or-reject>
      </div>
      <div class ="">
        <ng-template #suggestionOwnerDelete>
            <div *ngIf="suggestion.descriptionWasClicked" >
          <div class="checkTrue1 outer-from-pannel-check11" style="padding-left: 0px;" [@SuggestionIconsAnimation]="'in'"
          >
                <a href="" (click)="updateSuggestion(accord,suggestion._key, suggestion, $event); false">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </a>
                <button type="submit" class="hidden"></button>
          </div>
          <div class="resetIcon1 outer-from-pannel-close1" style="padding-left: 0px;" [@SuggestionIconsAnimation]="'in'"
          >
            <a (click)="resetSuggestion(i, suggestion)">
              <i class="fa fa-close" aria-hidden="true"></i>
            </a>
            <button type="submit" class="hidden"></button>
          </div>
        </div>
        </ng-template>
      </div>
    </div>
    <!-- <div class="col-xs-12 wrap-Button-votes">
      <button class="button-votes">Vote | 23</button>
    </div> -->
  </div>
  </div>