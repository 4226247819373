<div class="panel">
  <div class="headline">
    <h2 class="text-center">Upgrade your account</h2>
  </div>
  <div class="subhead">
    <h3 class="text-center">Add power features from $5/month</h3>
  </div>
  <div class="body">
    <button (click)="openCheckout()" class="btn btn-primary purchase-button">Purchase</button>
  </div>
</div>
