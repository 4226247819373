import { NgModule } from '@angular/core';
import { EnterQuestionComponent } from './enter-question/enter-question.component';
import { RoomResultsFilterComponent } from './room-results-filter/room-results-filter.component';
import { RoomResultsComponent } from './room-results/room-results.component';
import { RoomMakeChoicesComponent } from './room-make-choices/room-make-choices.component';
import { RoomComponent } from './room/room.component';
import { RoomOptionsComponent } from './room-options/room-options.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';
import { RoomStartChoicesComponent } from './room-start-choices/room-start-choices.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { Routes, RouterModule } from '@angular/router';

// guards
import { IsAccordGuard } from './guards/is-accord.guard';
import { IsMemberOfRoomGuard } from './guards/is-member-of-room.guard';
import { IsOwnerOfRoomGuard } from './guards/is-owner-of-room.guard';
import { IsCurrentChoiceSetGuard } from './guards/is-current-choice-set.guard';
import { IsChooseableGuard } from './guards/is-chooseable.guard';
import { IsFinishedShouldRedirectToResultsGuard } from './guards/is-finished-should-redirect-to-results.guard';
import { DetermineCorrectRouteFromRoomGuard } from './guards/determine-correct-route-from-room.guard';
import { AuthorizedGuard } from './guards/authorized.guard';

// import { SendAnonymousToLoginGuard } from './guards/send-anonymous-to-login.guard';

import { HistoryComponent } from './history/history.component';
import { EmailRecoverPasswordComponent } from './email-recover-password/email-recover-password.component';
import { DeleteProfileComponent } from './delete-profile/delete-profile.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'signup', 
    component: SignupComponent,
    data: { animation: "SignupPage" } 
  },
  { 
    path: 'login', 
    component: LoginComponent,
    data: { animation: "LoginPage" }
  },
  { path: 'recover-password', 
    component: EmailRecoverPasswordComponent,
    data: { animation: "EmailRecoverPasswordTransition" }
  },
  {
    path: 'delete-profile',
    component: DeleteProfileComponent,
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthorizedGuard],
    data: { animation : "ProfilePage"}
  },
  {
    path: 'plan',
    component: PlanSelectionComponent,
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthorizedGuard],
    data: { animation : "HistoryPage"}
  },
  {
    path: 'room/:id',
    redirectTo: '/room/:id/options',
    canActivate: [
      IsAccordGuard,
      AuthorizedGuard,
      IsMemberOfRoomGuard,
      IsOwnerOfRoomGuard
    ],

    pathMatch: 'full'
  },
  {
    path: 'room/:id/make-choices/:current',
    component: RoomMakeChoicesComponent,
    canActivate: [
      IsAccordGuard,
      AuthorizedGuard,
      IsMemberOfRoomGuard,
      IsFinishedShouldRedirectToResultsGuard,
      IsCurrentChoiceSetGuard
    ],
    data: { animation: "MakeChoices" },
    pathMatch: 'full'
  },
  {
    path: 'room/:id/start-choices',
    component: RoomStartChoicesComponent,
    canActivate: [
      IsAccordGuard,
      AuthorizedGuard,
      IsMemberOfRoomGuard,
      IsFinishedShouldRedirectToResultsGuard,
      IsChooseableGuard,
    ],
    pathMatch: 'full'
  },
  {
    path: 'room/:id/results',
    component: RoomResultsComponent,
    canActivate: [
      IsAccordGuard, 
      AuthorizedGuard, 
      IsMemberOfRoomGuard
      ],
    pathMatch: 'full',
    data: { animation: "RoomResults" }
  },
  {
    path: 'room/:id/results/filter',
    component: RoomResultsFilterComponent,
    canActivate: [
      IsAccordGuard, 
      AuthorizedGuard, 
      IsMemberOfRoomGuard
      ],
    pathMatch: 'full',
    data: { animation: "ResultFilterPage" }
  },
  {
    path: 'room/:id/options',
    component: RoomOptionsComponent,
    canActivate: [
      IsAccordGuard,
      AuthorizedGuard,
      IsMemberOfRoomGuard,
      DetermineCorrectRouteFromRoomGuard,
      
    ],
    data: { animation: "OptionsPage" },
    pathMatch: 'full'
  },
  {
    path: "enter-question",
    component: EnterQuestionComponent,
    canActivate: [AuthorizedGuard],
    data: { animation: "EnterPage" },
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthorizedGuard],
    data: { animation: "EnterPage" },
    pathMatch: "full"
  },
  { 
    path: 'notFound', 
    component: NotFoundComponent 
  },
  { path: '**', 
    redirectTo: '/notFound', 
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  providers: [
    IsMemberOfRoomGuard,
    IsOwnerOfRoomGuard,
    IsCurrentChoiceSetGuard,
    IsChooseableGuard,
    IsFinishedShouldRedirectToResultsGuard,
    IsAccordGuard,
    DetermineCorrectRouteFromRoomGuard,
    AuthorizedGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
