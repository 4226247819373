
import {combineLatest as observableCombineLatest, combineLatest, observable } from 'rxjs';
import { UtilService } from '../core/util.service';
import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { animate, transition, trigger, style, state, group, query, stagger } from "@angular/animations";
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AccordUser, DataService, Accord, AccordState } from '../data.service';
@Component({
  selector: 'app-room-options',
  templateUrl: './room-options.component.html',
  styleUrls: ['./room-options.component.css'],
  animations: [
    trigger('main', [
      state('in', style({
      })),
      transition('void => *', [
        style({ width: '*', transform: 'translateY(-200px)', opacity: 0 }),
        group([
          animate('1s 1000ms ease', style({ opacity: 1, width: '*', transform: 'translateY(0px)' }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('1s 900ms ease', style({ opacity: 1,  width: '*' }))
        ])
      ])
    ]),

    trigger('RoptionsList', [
      transition(":enter", [
        query(".headline", [
          style({ opacity: 0, transform: "translateY(-100px)", overflow:'hidden' }),
          stagger(200, [
            animate(
              "1s 500ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none", overflow:'hidden' })
            )
          ])
        ], { delay: 700 })
      ]),
      transition(':leave', [
        query(".wrapper", [
          style({ opacity: 1, height:'14%', transform: 'translateX(0%)', overflow:'hidden' }),
          stagger(100, [
            animate('500ms 400ms ease-out', style({ height:'14%',transform: 'translateX(-100%)', opacity: 1, overflow:'hidden' }))
          ])
        ], { optional: true })
      ])
    ]),

    // Other User Suggestion's animation up/down
    trigger('suggestionsAnimate', [
      transition(":enter", [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class RoomOptionsComponent implements OnInit {
  // @HostBinding('@RoptionsList')

  showContent: Boolean = false;
  public smartLinkManager: boolean;
  public roomId: string;
  public accord: Accord;
  public accordUser: AccordUser;
  public uid: any;
  public accord_: AngularFireDatabase;
  public expanded = true;
  public AccordState = AccordState;
  public killInterval;
  public accord1

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataService,
    private util: UtilService
  ) {}

  ngOnInit() {
   
    observableCombineLatest(this.data.accordUser, this.route.params).subscribe(
      results => {
        this.accordUser = results[0];
        const params = results[1];
        this.uid = this.accordUser.uid;
        this.roomId = params.id;
        this.data.get(this.roomId).subscribe(room => {
          this.accord = room;
          if (!room.state)
            this.data.setAccordState(this.accord, AccordState.Brainstorming);
        });
        this.data.optionDeleted.subscribe(key => {
          if (key != null) this.data.rebuildChoices(this.accord, true);
        });
      }
    );
  
        this.killInterval = setInterval(() => {
          this.checkAccord();
      }, 2000)
  
  }

  displaySmartLinkManager() {
    this.smartLinkManager = true;
    return false;
  }
  checkAccord (){
    this.data.accord$.subscribe(accord => (this.accord1 = accord));
      if(this.accord1.state === AccordState.Active){
        window.location.reload();
      }
  }
  canBeReadyForChoosing() {
    if (this.accord.state === AccordState.Active) {
      return true;
    }
    if (
      this.accord.state < AccordState.Active &&
      (this.accord.options &&
        this.util.objectToArray(this.accord.options).length > 1) &&
      this.accord.ownerId === this.uid
    ) {
      return true;
    }
    return false;
  }

  canSuggest() {
    if (this.accord.state < AccordState.Active) {
      return true;
    }
    return false
  }

  ngOnDestroy() {
    if (this.killInterval) {
      clearInterval(this.killInterval);
    }
  }

}
