<div class="container">
<div class="row" *ngIf="accord">
  <div class="col-sm-12">
    <div class="room">
    <!-- <div class="panel">
        <h2 class="">Review Options</h2>
      </div> -->
      <!-- <div class="question-display">
      <app-question-display [user]="accordUser"></app-question-display>
      </div> -->
      <div class="room-make-choices-chooser">
      <app-room-make-choices-chooser></app-room-make-choices-chooser>
      </div>
    </div>
  </div>
</div>
</div>
