import { Accord, DataService } from "../data.service";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";

@Injectable()
export class IsAccordGuard implements CanActivate {
  constructor(private data: DataService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.data.get(next.params.id).map((accord: Accord) => {
      if (accord && accord.roomId) {
        return true;
      }
      this.router.navigate(["/notFound"]);
      return false;
    });
  }
}
