
import {zip as observableZip, of as observableOf,  Observable } from 'rxjs';
import { DataService, AccordUser, Accord } from '../data.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class IsCurrentChoiceSetGuard implements CanActivate {
  accord: Accord;
  roomId: string;
  accordUser: AccordUser;

  constructor(
    private data: DataService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (next.params.current) {
      return observableOf(true);
    }
    // changed from Observable.combineLatest
    return Observable.zip(this.data.get(next.params.id), this.data.accordUser).first()
      .map(results => {
        this.accord = results[0];
        this.accordUser = results[1];
        const choiceKeys = Object.keys(this.accord.choices);
        this.router.navigate(['/room',this.accord.roomId,'make-choices',choiceKeys[0]]);
        return false;
      });
  }
}
