<div class="roomOptions-wrapper" *ngIf="accord" [@main]="'in'">
  <p class="headline">
    Let's get the discussion started
  </p>
  
  <div class="row question-display" >
    <div class="col-xs-12">
      <app-question-display [user]="accordUser"></app-question-display>
    </div>
    <div class="col-xs-12">
      <!-- <i class="userAvatar fa fa-user" aria-hidden="true"></i>
      <p class="text-paragraph">Margret Jonathan</p> -->
    </div>
    <div class="col-xs-12">
      <app-room-link-display></app-room-link-display>
    </div>
  </div>

  <!-- <div class="row enter-suggestions" [@RoptionsList]="'in'"> -->
    <div class="row enter-suggestions">
    <div class="col-xs-12" >
      <app-room-option-list *ngIf="accord.options"></app-room-option-list>
    </div>

    <div
      class="col-xs-12"
      *ngIf="
        accord.ownerId === uid && accord.state <= AccordState.ReadyForChoosing
      "
    >
      <app-room-owner-option-entry
        *ngIf="accord.state <= AccordState.ReadyForChoosing"
      >
      </app-room-owner-option-entry>
    </div>
    <div class="col-xs-12">
      <app-room-member-suggestion-entry
        *ngIf="
          canSuggest() &&
          accord.ownerId !== uid &&
          accord.state < AccordState.ReadyForChoosing
        "
      >
      </app-room-member-suggestion-entry>
    </div>
  </div>

  <div class="row suggestions-wrapper">
    <div class="col-xs-12">
      <div class="suggestions">
        <app-room-suggestion-count-heading (click)="expanded = !expanded" >
        </app-room-suggestion-count-heading>
        <div class="row" *ngIf="expanded" [@suggestionsAnimate]="expanded">
          <div class="col-sm-4 col-xs-12">
            <app-room-member-suggestion-list
              *ngIf="accord.suggestions && accord.state < AccordState.Active"
              
            ></app-room-member-suggestion-list>
          </div>
          <div class="col-sm-8 col-xs-12">
            <!-- <app-room-member-suggestion-entry *ngIf="canSuggest() && accord.ownerId !== uid && accord.state < AccordState.ReadyForChoosing"></app-room-member-suggestion-entry> -->
            <app-room-suggestion-list
              *ngIf="accord.suggestions"
            ></app-room-suggestion-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="accord.ownerId == uid">
    <div class="col-xs-12">
      <app-room-lock *ngIf="canBeReadyForChoosing()"></app-room-lock>
    </div>
  </div>
</div>
