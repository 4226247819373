import { Component, OnInit, HostBinding } from "@angular/core";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { animate, transition, trigger, style, query, stagger, state,group } from "@angular/animations";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { DataService } from "../data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "angular-2-local-storage";
import { FacebookLoginComponent } from "app/facebook-login/facebook-login.component";
import { ToastrManager } from "ng6-toastr-notifications";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
  animations: [
    trigger("SignUpAnimations", [
      transition(":enter", [
        query(".logo, .text-allign, .button-allign, .divider, .animate-name , .animate-email, .animate-password, .wrap, .allign-text, .terms ", [
          style({ opacity: 0, transform: "translateY(-100px)", overflow:'hidden' }),
          stagger(150, [
            animate(
              "1s 1000ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none", overflow:'hidden' })
            )
          ])
        ])
      ]),
      transition(":leave", [
        // query(".logo, .text-allign, .button-allign, .divider, form", [
        query(".loginContainer",[
        style({ transform: "translateX(0%)", opacity: 1, top : 0, bottom: 0, overflow:'hidden' }),
        stagger(100, [
        animate('500ms 100ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateX(-100%)", overflow:'hidden' }))
      ])
    ], { optional: true })
    ])
    ])
  ]
})
export class SignupComponent implements OnInit {
  @HostBinding("@SignUpAnimations")

  public transitionEffect: boolean = false;
  public userGoogle: any = new firebase.auth.GoogleAuthProvider();
  public userFacebook = new firebase.auth.FacebookAuthProvider();
  public canLink: boolean;
  form;
  errorMessage: string;
  signupForm: FormGroup;
  user: EmailSignupUser;
  public firstName: string;
  public facebook: string;
  public email: string;
  public password: string;
  public displayLogo = false;
  constructor(
    private fb: FormBuilder,
    public af: AngularFireAuth,
    private data: DataService,
    private router: Router,
    private localStorage: LocalStorageService,
    private toastr: ToastrManager
  ) {
    this.createForm();
  }
  createForm() {
    this.signupForm = this.fb.group({
      firstName: ["", Validators.required],
      // lastName: ['', Validators.required],
      email: ["", Validators.required],
      password: ["", Validators.required]
      // verifyPassword: ['', Validators.required]
    });
  }

  onSubmit() {
    const formModel = this.signupForm.value;
    if (
      formModel.firstName !== "" &&
      formModel.email !== "" &&
      formModel.password !== ""
    ) {
      const signupUser: EmailSignupUser = Object.assign({}, formModel);
      this.errorMessage = "";
      this.transitionEffect = true;
      this.af.auth
        .createUserWithEmailAndPassword(signupUser.email, signupUser.password)
        .then((state: any) => {
          firebase.auth().onAuthStateChanged((state: any) => {
            state
              .updateProfile({
                displayName: signupUser.firstName,
                photoURL: null
              })
              .then(() => {
                this.data.updateUserProfile(signupUser);
                const returnUrl = this.localStorage.get<string>("routerState.Url");
                if (returnUrl) {
                  this.toastr.successToastr("Success!", "Logged In!", {
                    toastTimeout: '700',maxShown: (1),position: ('bottom-right')
                  });
                  this.localStorage.remove("routerState.Url");
                  this.router.navigateByUrl(returnUrl);
                } else {
                  this.router.navigateByUrl("/");
                }
              });
          });
        })
        .catch(err => {
          this.transitionEffect = false;
          this.enableForm();
          this.errorMessage = err.message;
          console.log(err);
        });
    } else {
      this.toastr.errorToastr("Error!", "Some field is missing!", {
        toastTimeout: '700',maxShown: (1),position: ('bottom-right')
      });
    }
  }

  disableForm() {
    this.signupForm.controls["firstName"].disable();
    this.signupForm.controls["email"].disable();
    this.signupForm.controls["password"].disable();
  }

  enableForm() {
    this.signupForm.controls["firstName"].enable();
    this.signupForm.controls["email"].enable();
    this.signupForm.controls["password"].enable();
  }

  googleLogin() {
    firebase
      .auth()
      .signInWithPopup(this.userGoogle)
      .then(function(result) {
        var token = result.credential;
        var user = result.user;
        this.authState = user;
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  facebookLogin() {
    firebase
      .auth()
      .signInWithPopup(this.userFacebook)
      .then(function(result) {
        var token = result.credential;
        var user = result.user;
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  getFacebookuser() {
    firebase
      .auth()
      .getRedirectResult()
      .then(function(result) {
        if (result.credential) {
          var token = result.credential;
        }
        var user = result.user;
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  getGoogleUser() {
    firebase
      .auth()
      .getRedirectResult()
      .then(function(result) {
        if (result.credential) {
          var token = result.credential;
        }
        var user = result.user;
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  ngOnInit() {
    this.getFacebookuser();
    this.getGoogleUser();
  }
}

export class EmailSignupUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  verifyPassword: string;
}
