<div class="container container-room-results" *ngIf="showContent">
  <div class="row" *ngIf="accord">
    <!-- Room result question description -->
    <div class="question-display col-sm-12 headline">
      {{ accord.question }}
    </div>
    <!-- Room result graph component -->
    <div class="showResults col-md-12 col-sm-12 col-xs-12">
      <app-room-results-graph></app-room-results-graph>
    </div>
    <!-- Filter Results -->
    <div class="col-md-12 col-sm-12  col-xs-12 need-narrow" style="margin-top: 20px; margin-bottom: 20px;">
      <a
        [routerLink]="['/room', roomId, 'results', 'filter']"
        class="filterResults"
        >Need to narrow it down?
      </a>
    </div>
    <!-- Export CSV Component -->
    <div class="col-sm-10 col-xs-10 export-csv">
      <button class = "exportButtonlg">
      <app-room-export-csv [room]="accord"></app-room-export-csv> EXPORT </button>
    </div> 
  </div>
</div>