
import { Component, OnInit, AfterViewInit, HostBinding } from "@angular/core";
import { animate, transition, trigger,query,stagger, style } from "@angular/animations";
import { DataService } from "app/data.service";
import { LocalStorageService } from "angular-2-local-storage";
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from "@angular/router";
import { EmailSignupUser } from "app/signup/signup.component";
import { ToastrManager } from 'ng6-toastr-notifications';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
  animations: [
    trigger('profileAnimations', [
      transition(":enter", [
        query(".wrapper, .profile-image-holder, .profile-info, .trnstn_name, .trnstn_email, .trnstn_password, .trnstn_fb, .trnstn_google, .saveButton ", [
          style({ opacity: 0,  transform: "translateY(-100px)", overflow:'hidden' }),
          stagger(200, [
            animate(
              "1s 500ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1,  transform: "none", overflow:'hidden' })
            )
          ])
        ], { optional: true })
      ]),
      transition(':leave', [
        query(".wrapper", [
          style({ opacity: 1, height:'14%', transform: 'translateX(0%)', overflow:'hidden' }),
          stagger(100, [
            animate('500ms 400ms ease-out', style({ height:'14%',transform: 'translateX(-100%)', opacity: 1, overflow:'hidden' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class ProfileComponent implements AfterViewInit {
  @HostBinding('@profileAnimations')
  profile;

  email: string;
  password: string;
  displayName: string;
  googleEmail: string;
  facebookEmail: string;
  message: string;
  public profileSubmitTransition: boolean = false;
  public canLink: boolean;
  // public userFacebook: FirebaseAuthState;
  public returnUrl: string;
  // public auth: FirebaseAuthState;
  auth: any = null;
  public passwordFieldDisabled: boolean;
  public emailFieldDisabled: boolean;
  user: EmailSignupUser;
  public editName = false;
  public editEmail = false;
  public editPassword = false;
  public inputName: string;
  public staticPassword = "******";
  public showName: boolean = false;
  public showPassword: boolean = false;
  private currentUser = firebase.auth().currentUser;
 

  constructor(
    private data: DataService,
    private router: Router,
    private af: AngularFireAuth,
    private localStorage: LocalStorageService,
    private toastr: ToastrManager
  ) {
    data.displayName.subscribe(name => {
      this.displayName = name;
    });
    af.authState.subscribe(authState => {
      this.auth = authState;
      this.returnUrl = this.localStorage.get<string>("routerState.Url");
    });
  }
  updateDisplayProfile() {
    this.profileSubmitTransition = true;
    this.toastr.successToastr('Success!', 'Profile Updated!',{
      toastTimeout: '700',maxShown: (1),position: ('bottom-right')
    });
    this.currentUser
      .updateProfile({
        // displayName: signupUser.firstName + ' ' + signupUser.lastName,
        displayName: this.displayName,
        photoURL: null
      })
      .then(res => {
        this.currentUser.updatePassword(this.password).then(response => {
        })
        this.profileSubmitTransition = true;
        this.router.navigateByUrl('enter-question');
      })
      .catch((err: any) => {
        this.profileSubmitTransition = false;
      });
  }

  changeEditMode(inputName) {
    switch (inputName) {
      case "displayName": {
        this.editName = !this.editName;
        this.showName = !this.showName;
        break;
      }
      case "email": {
        this.editEmail = !this.editEmail;
        break;
      }
      case "password": {
        this.editPassword = !this.editPassword;
        this.showPassword= !this.showPassword
        break;
      }
      default: {
        break;
      }
    }
  }

  ngAfterViewInit() {
      this.af.authState.
      filter(a => a !== null)
      .subscribe(auth => {
        this.af.authState.subscribe(user => {

          if (user) {
            if (this.af.auth.currentUser.providerData[0].providerId == "google.com"){
              this.googleEmail = this.af.auth.currentUser.email;    
            }
            this.displayName = this.af.auth.currentUser.displayName;
            this.email = this.af.auth.currentUser.email;
            this.emailFieldDisabled = true;
            this.passwordFieldDisabled = true;
          }

          //  else if (user.facebook) {
          //   this.displayName = this.af.auth.currentUser.displayName;
          //   this.facebookEmail = this.af.auth.currentUser.email;
          //   this.emailFieldDisabled = true;
          //   this.passwordFieldDisabled = true;
          // } 
          else {
            this.displayName = auth.displayName;
            this.email = auth.email;
            this.emailFieldDisabled = true;
            this.passwordFieldDisabled = false;
          }
          // this.animatePage = true
        });
      });
  }
}
