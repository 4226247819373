    <div *ngIf="results">
  <div class="row d-flex-custom" style="margin-top: 6px;" *ngFor="let result of results ; let i=index" >
    <!-- If Result contain images -->
    <div class="col-xs-3" *ngIf="hasImages">
      <img
        *ngIf="result.url"
        [src]="result.url"
        alt=""
        class="results-img img img-responsive"
        (click)="open(i)"
      />
    </div>
    <div class="room-result-wrapper col-xs-9">
      <!-- Result Options Description -->
      <div class="result-option-description clearfix cursor-pointer" >
        <h4
          [ngClass]="{ highestVotedDescription: maxWins == result.wins }"
          class="chart-label"
        >
          {{ result.description }}
        </h4>
        <h5 [ngClass]="{ highestVotedDescription: maxWins == result.wins }">
          {{ result.wins }}
        </h5>
      </div>
      <!-- Progress Bar -->
      <div class="background-progressbar">
      <div 
        [ngClass]="{ highestVotes: maxWins == result.wins }"
        class="well custom-progressbar purple"
        [style.width]="percent(result.wins) + '%'"
        *ngIf="result.wins > 0"
      ></div>
      </div>
      <!-- No Results -->
      <!-- <div class="well zero" *ngIf="result.wins == 0">NO RESULTS</div> -->
    </div>
    <!--End If Result contain images -->
  </div>
</div>
