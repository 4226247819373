import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class UtilService {
  constructor() { }
  findUrls(text) {
    const source = (text || '').toString();
    const urlArray = [];
    let matchArray;

    // Regular expression to find FTP, HTTP(S) and email URLs.
    const regexToken = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)|((mailto:)?[_.\w-]+@([\w][\w\-]+\.)+[a-zA-Z]{2,3})/g;

    // Iterate through any URLs in the text.
    return Observable.create(observer => {
      while ((matchArray = regexToken.exec(source)) !== null) {
        const token = matchArray[0];
        urlArray.push(token);
      }
      observer.next(urlArray);
      observer.complete();
    });
  }
  objectToArray(obj) {
    const arr = new Array<any>();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key]._key = key;
        arr.push(obj[key]);
      }
    }
    return arr;
  }
  filterArray(array: Array<any>, filter) {
    const arr = new Array<any>();
    array.forEach((value, index) => {
      let valid = true;
      for (const predicate in filter) {
        if (value.hasOwnProperty(predicate)) {
          if (value[predicate] !== filter[predicate]) {
            valid = false;
          }
        }
      }
      if (valid) {
        arr.push(value);
      }
    });
    return arr;
  }

  shuffle(array: Array<any>) {
    const inputArray: any[] = array;
    for (let i: number = inputArray.length - 1; i >= 0; i--) {
      const randomIndex: number = Math.floor(Math.random() * (i + 1));
      const itemAtIndex: number = inputArray[randomIndex];
      inputArray[randomIndex] = inputArray[i];
      inputArray[i] = itemAtIndex;
    }
    return inputArray;
  }
  isImage(url: string) {
    if (url && (url.toLowerCase().indexOf('.png') > 0
      || url.toLowerCase().indexOf('.jpg') > 0
      || url.toLowerCase().indexOf('.gif') > 0
      || url.toLowerCase().indexOf('.svg') > 0)) {
      return true;
    }
    return false;

  }
}
