
import { tap, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth'
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private afAuth: AngularFireAuth) {}
  isLoggedIn(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      take(1),
      map((authState) => !!authState),
      tap(authenticated => {
        return authenticated;
      }),);
  }
}
