
import {filter} from 'rxjs/operators';
import * as url from 'url';
import { Component, OnInit, Input,  EventEmitter,  ViewChild} from '@angular/core';
import {
  AccordSuggestion,
  AccordService,
  UtilService,
  EmbedlyService
} from '../core';
import { Accord, DataService, AccordUser, AccordOption } from '../data.service';
import { trigger, transition, animate, style, state, group } from '@angular/animations';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-room-member-suggestion-entry',
  templateUrl: './room-member-suggestion-entry.component.html',
  styleUrls: ['./room-member-suggestion-entry.component.css'],
  animations: [
    trigger('IconsAnimation2', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ width: '*', transform: 'translateX(-20px)', opacity: 1 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*', transform: 'translateX(0px)' }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('1ms ease', style({ opacity: 0, width: '*' }))
        ])
      ])
    ])
  ]
})
export class RoomMemberSuggestionEntryComponent implements OnInit {
  public isCurrentlyUploading: any;
  public url: any;
  public accordUser: AccordUser;
  public canUse: boolean;
  public suggestion: string;
  public accord: Accord;
  public showCheckIcon2: boolean = false;

  @ViewChild('suggestionEntryForm') public suggestionEntryForm : NgForm;
  public myFocusTriggeringEventEmitter = new EventEmitter<boolean>();

  constructor(private util: UtilService, private data: DataService) {}

  ngOnInit() {

    this.showCheckIcon2 = false;
    this.isCurrentlyUploading = false;
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.pipe(filter(a => a !== null)).subscribe(user => {
      this.canUse = true;
      this.accordUser = user;
    });
    this.data.newSuggestionAdded.pipe(
      filter(a => a !== null))
      .subscribe(suggestion => {
        this.suggestion = '';
        this.url = '';
        console.log('New suggestion added.');
      });
  }
  // setUrl(event) {
  //   console.log(event);
  //   this.url = event;
  // }
  // setFileName(event) {
  //   console.log(event);
  //   this.suggestion = event;
  // }
  // isUploading(event) {
  //   this.isCurrentlyUploading = event;
  // }

  ngAfterViewInit() {
    this.myFocusTriggeringEventEmitter.emit(true);
  }


  enterSuggestion() {
  
    const suggestion = new AccordOption();
    suggestion.description = this.suggestion;
    suggestion.member = this.accordUser.uid;

    this.util.findUrls(this.suggestion).subscribe(
      urls => {
        if (this.url) {
          suggestion.url = this.url;
        }
        if (urls.length > 0 || suggestion.url) {
          if (!suggestion.url) {
            suggestion.url = urls[0];
          }
        } else {
          suggestion.url = null;
        }
        this.data.pushSuggestion(this.accord, suggestion);
        this.showCheckIcon2 = false;
        this.suggestionEntryForm.reset();
        setTimeout( () =>{
          this.myFocusTriggeringEventEmitter.emit(true);
          this.showCheckIcon2 = false;

          }, 1000)
      },
      error => {}
    );

  }

  showTickIcon(event) {
    if(event.keyCode == 13){
      this.showCheckIcon2 = false;
      return;
    }
    if(this.suggestion == "" ){
      this.showCheckIcon2 = false;
    }
    else {
      this.showCheckIcon2 = true;
    }
    return this.showCheckIcon2;
  }
}
