
import {filter} from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, Input, ViewChild, EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilService } from '../core/';
import { DataService,Accord,AccordUser,AccordOption } from '../data.service';
import { trigger, transition, animate, style, state, group } from '@angular/animations';
import { AnimationService, AnimationBuilder } from "css-animator";

@Component({
  selector: 'app-room-owner-option-entry',
  templateUrl: './room-owner-option-entry.component.html',
  styleUrls: ['./room-owner-option-entry.component.css'],
  animations: [
    trigger('optionlistInOut', [
      state('in', style({
        transform: 'translateX(-50px)', opacity: 1
      })),
      transition('* => *', [
        style({ width: 10, transform: 'translateX(10px)', opacity: 0 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*' }))
        ])
      ]),
      transition('* => *', [
        group([
          animate('900ms ease', style({ opacity: 1, width: 10 }))
        ])
      ])
    ]),
    trigger('IconsAnimation1', [
      state('in', style({ opacity: 1})),
      transition(':enter', [
        style({ width: '*', transform: 'translateX(-20px)', opacity: 1 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*', transform: 'translateX(0px)' }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('2ms ease', style({ opacity: 0, width: '*' }))
        ])
      ])
    ])
  ]
})
export class RoomOwnerOptionEntryComponent implements OnInit, AfterViewInit {
  public accord: Accord;
  @ViewChild('optionEntryForm') public optionEntryForm: NgForm;

  public myFocusTriggeringEventEmitter = new EventEmitter<boolean>();

  public accordUser: AccordUser;
  public canUse = false;
  public suggestion: string;
  public url: string;
  public isCurrentlyUploading: boolean = false;
  private animator: AnimationBuilder;
  public focusInput: boolean = false;
  public display: boolean;
  public showUpload: false;
  public showCheckIcon1: boolean = false;
  public formValid: boolean = false;
  constructor(private data: DataService, private util: UtilService, private element: ElementRef,
    private animationService: AnimationService) {
      this.animator = animationService.builder();
    }

  ngOnInit() {
    this.showCheckIcon1 = false;
    this.isCurrentlyUploading = false;
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.filter(a => a !== null).subscribe(user => {
      this.canUse = true;
      this.accordUser = user;
    });
    this.data.newOptionAdded.filter(x => x !== null).subscribe(newOption => {
      this.suggestion = '';
      this.url = '';
      this.data.rebuildChoices(this.accord, true);
    });
    this.data.newSuggestionAdded
      .filter(x => x != null)
      .subscribe(newSuggestion => {
        this.suggestion = '';
        this.url = '';
      });
    this.data.rebuiltChoices.filter(x => x !== null).subscribe(choices => {
    });
  }

  ngAfterViewInit() {
    this.optionEntryForm.statusChanges.subscribe((status: string) => {
      this.formValid = status === 'VALID';
    });
    this.myFocusTriggeringEventEmitter.emit(true);
    this.showCheckIcon1 = false;
  }

  isUploading(event) {
    this.isCurrentlyUploading = event;
  }
  inputHasFocus() {
    return this.focusInput;
  }
  uploadComplete(downloadUrl) {
    this.url = downloadUrl;
    this.showUpload = false;
    if (!this.suggestion) {
      this.optionEntryForm.form.controls.suggestion.markAsDirty();
    }
  }

  clearUrl() {
    this.url = null;
  }
  showcheck(event) {
    if(event.keyCode == 13){
    this.clearUrl();
    this.showCheckIcon1 = false;
    return;
    }
    if(this.suggestion == "" ) {
    this.showCheckIcon1 = false;
    }
    else {
    this.showCheckIcon1 = true;
    }
    return this.showCheckIcon1;
  }

  enterOption() {
    this.display = false;

    if (this.accord.ownerId === this.accordUser.uid ) {
      this.showCheckIcon1 = false;
      const suggestion = new AccordOption();
      suggestion.description = this.suggestion;
      suggestion.member = this.accordUser.uid;
      this.util.findUrls(this.suggestion).subscribe(
        urls => {
          if (this.url) {
            suggestion.url = this.url;
          }
          if (urls.length > 0 || suggestion.url) {
            if (!suggestion.url) {
              suggestion.url = urls[0];
            }
          } else {
            suggestion.url = null;
          }
        
        this.data.pushOption(this.accord, suggestion);
        // this.animator.setType("fadeInLeft").show(this.element.nativeElement)
          this.showUpload = false;
          this.showCheckIcon1 = false;
          this.optionEntryForm.reset();
          setTimeout( () =>{
          this.myFocusTriggeringEventEmitter.emit(true);

          }, 1000)

        },
        error => {
          console.log(error);
        }
      );
    }
  }


}
