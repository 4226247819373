import { UtilService } from './util.service';
import { Injectable, Inject } from '@angular/core';
import { FirebaseApp,FirebaseAuth } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
@Injectable()
export class AccordService {
  public currentAuth: FirebaseAuth;

  constructor(private af: AngularFireAuth,private db: AngularFireDatabase, private util: UtilService, @Inject(FirebaseApp) private firebaseApp: any) {
    
    af.authState.subscribe((authState:any) => {
      this.currentAuth = authState;
    }, error => console.log(error), () => {
      console.log(this.currentAuth);
    });
  }

  uploadFile(authState: any, accordId: string, fileName: string, file: File) {
    if (authState) {
      const path = '/accord/accords/' + accordId + '/optionFiles/' + authState.uid + '/' + fileName;
      const storageRef = this.firebaseApp.storage().ref().child(path);
      const meta = {
        contentType: file.type
      };
      return storageRef.put(file, meta);
    }
  }

  updateStripeInfo(info: any) {
    return this.db.object('/accord/users/' + this.currentAuth.currentUser.uid + '/stripe').update(info);
  }

  getAccord(roomId: string) {
    return this.db.object('/accord/accords/' + roomId);
  }
  $accord(accordId: string) {
    return this.db.object('/accord/accords/' + accordId);
  }

  addQuestion(question: string) {
    return this.db.list('/accord/accords')
      .push({ question: question, ownerId: this.currentAuth.currentUser.uid })
      .then((pushQuestionResult) => {
        const roomId = pushQuestionResult.key;
        return this.db
          .object('/accord/users/' + this.currentAuth.currentUser.uid + '/rooms/' + roomId)
          .set({ question: question, createdOn: Date.now() }).then(() => {
            return this.joinAccord(roomId, this.currentAuth.currentUser.uid, true, this.currentAuth)
              .then(() => {
                return { status: 'created', pushQuestionResult, roomId };
              });
          });
      });
  }

  addOption(accord, option: AccordOption) {
    return this.db.list('/accord/accords/' + accord.$key + '/options').push(option);
  }
  setOption(accord, option: AccordOption, key: string) {
    return this.db.object('/accord/accords/' + accord.$key + '/options/' + key).set(option);
  }

  addSuggestion(accord, suggestion: AccordSuggestion) {
    if (this.currentAuth) {
      this.joinAccord(accord.$key, this.currentAuth.currentUser.uid, false, this.currentAuth);
    }
    return this.db.list('/accord/accords/' + accord.$key + '/suggestions').push(suggestion);
  }

  $suggestions(accord: any) {
    return this.db.list('/accord/accords/' + accord.$key + '/suggestions');
  }

  approveSuggestion(accord, suggestion: AccordSuggestion, key: string) {
    return this.setOption(accord, <AccordOption>suggestion, key)
      .then((setOptionResult) => {
        return this.removeSuggestion(accord, suggestion, key);
      });
  }
  readyForChoices(accord) {
    if (this.currentAuth.currentUser.uid === accord.ownerId) {
      return this.db.object('/accord/accords/' + accord.$key).update({ readyForChoices: true });
    }
  }
  lockAccord(accord) {
    if (this.currentAuth.currentUser.uid === accord.ownerId && !accord.isLocked) {
      return this.db.object('/accord/accords/' + accord.$key).update({ isLocked: true });
    }
  }

  membersWithChoices(accord) {
    const members = this.util.objectToArray(accord.members);
    const choices = this.util.objectToArray(accord.choices);
    members.forEach((value, index) => {
      choices.forEach((choice, choiceIndex) => {
        if (choice.members) {
          members.forEach((memberChoice, memberChoiceIndex) => {
            if (!value.hasChoice && memberChoice._key === value.id) {
              value.hasChoice = true;
            }
          });
        }
      });
    });
    // sort by name
    return this.util.filterArray(this.util.objectToArray(accord.members), { 'hasChoice': true }).sort((a, b) => {
      if (b.name === undefined) { return 1; }
      if (a.name === undefined) { return -1; }
      if (a.name > b.name) { return 1; }
      if (a.name < b.name) { return -1; }
      return 0;
    });
  }
  private optionsToAccordChoiceArray(accordOptions: any) {
    const choiceArray = Array<AccordChoice>();
    for (const key in accordOptions) {
      if (accordOptions.hasOwnProperty(key)) {
        const choice = new AccordChoice();
        if (accordOptions[key].data) {
          choice.data = accordOptions[key].data;
        }
        if (accordOptions[key].url) {
          choice.url = accordOptions[key].url;
        }
        choice.description = accordOptions[key].description;
        choice.member = accordOptions[key].member;
        choice.selectId = key;
        choiceArray.push(choice);
      }
    }
    return choiceArray;
  }
  // choose(accord, choice, option) {
  //   return this.db.object('/accord/accords/' + accord.$key + '/choices/' + choice.key + '/members/' + this.currentAuth.uid)
  //     .set({
  //       option,
  //       displayName: this.currentAuth.currentUser.displayName
  //     }
  //     );
  // }
  prepareChoices(accord) {
    const choices = Array<Choice>();
    const potentialChoices = this.optionsToAccordChoiceArray(accord.options);
    if (this.currentAuth.currentUser.uid === accord.ownerId) {
      potentialChoices.forEach((val, index) => {
        potentialChoices.forEach((val2, index2) => {
          if (index2 > index) {
            const choice = new Choice();
            choice.option1 = val;
            choice.option2 = val2;
            choices.push(choice);
          }
        });
      });
      const listRef = this.db.list('/accord/accords/' + accord.$key + '/choices');
      return listRef.remove().then(() => {
        choices.forEach((choice) => {
          listRef.push(choice);
        });
        return listRef;
      });
    }
  }

  removeSuggestion(accord, suggestion: AccordSuggestion, key: string) {
    const ref = this.db.object('/accord/accords/' + accord.$key + '/suggestions/' + key);
    return ref.remove();
  }

  setShortLink(roomId: string, linkResults: any) {
    return this.db.object('/accord/accords/' + roomId).update(linkResults).then((updateResults) => {
      return this.db.object('/accord/links/' + linkResults.code).set({ roomKey: roomId, results: linkResults });
    });
  }

  castToRoomUser(user) {
    return { 'name': user.auth.displayName, 'id': user.uid, 'image': user.auth.photoURL, 'suggestions': 0, 'isOwner': null };
  }

  isMemberOfAccord(accordId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.currentAuth) return resolve(false);
      if (!this.currentAuth.currentUser.uid) return resolve(false);
      this.db.object('/accord/accords/' + accordId + '/members/' + this.currentAuth.currentUser.uid).valueChanges().subscribe((member) => {
        if (member) {
          return resolve(true);
        }
        return resolve(false);
      });
    });
  }

  isOwnerOfAccord(accordId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.currentAuth) {
        console.log('isOwnerOfAccord - no currentAuth', false);
        return resolve(false);
      }
      if (!this.currentAuth.currentUser.uid) {
        console.log('isOwnerOfAccord - no currentAuth.uid', false);
        return resolve(false);
      }
      this.db.object('/accord/accords/' + accordId + '/ownerId').valueChanges().subscribe((ownerId: any) => {
        console.log(ownerId.$value === this.currentAuth.currentUser.uid);
        return resolve(ownerId.$value === this.currentAuth.currentUser.uid);
      });
    });
  }


  joinAccord(roomId, userId, isOwner, user) {
    const roomUser = this.castToRoomUser(user);
    roomUser.isOwner = isOwner;
    return this.db.object('/accord/accords/' + roomId + '/members/' + userId)
      .set(roomUser).then(() => {
        return true;
      });
  }
  userAccords() {
    return this.db.list('/accord/users/' + this.currentAuth.currentUser.uid + '/rooms');

  }
}

export class AccordOption {
  description: string;
  member: string;
  url: string;
  data: any;
}

export class AccordChoice {
  description: string;
  member: string;
  url: string;
  data: any;
  selectId: string;
}

export class AccordSuggestion {
  description: string;
  member: string;
  url: string;
  data: any;
}

export class Choice {
  option1: AccordChoice;
  option2: AccordChoice;
}
