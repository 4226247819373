import { AccordUser, Accord, AccordState, DataService } from '../data.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class DetermineCorrectRouteFromRoomGuard implements CanActivate {
  constructor(private data: DataService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Observable.zip(
      this.data.get(next.params.id),
      this.data.accordUser.filter(x => x !== null)
    ).map(results => {
      const accord = results[0] as Accord;
      const user = results[1] as AccordUser;
      if (
        accord.state === AccordState.Active ||
        accord.state === AccordState.ReadyForChoosing
      ) {
        if (accord.ownerId === user.uid) {
            let currentKey  = Object.keys(accord.choices);
            this.router.navigate(['/room',accord.roomId,'make-choices',currentKey[0]]);
          return true;
        } else {
          this.router.navigate(['/room', next.params.id, 'start-choices']);
          return false;
        }
      } else if (accord.state === AccordState.Brainstorming) {
        return true;
      } else if (accord.state === AccordState.Finished) {
        this.router.navigate(['/room', next.params.id, 'results']);
        return false;
      } else {
        return true;
      }
    });
  }
}
