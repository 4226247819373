import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthService } from '../auth.service';

import { AngularFireAuth } from 'angularfire2/auth';

@Injectable()
export class AuthorizedGuard implements CanActivate {
  constructor(
    private af: AngularFireAuth,
    private router: Router,
    private localStorage: LocalStorageService,
    private auth: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // get the auth status
    return this.auth.isLoggedIn().map(isLoggedIn => {
      if (!isLoggedIn) {
        this.localStorage.set('routerState.Url', state.url);
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    });
  }
}
