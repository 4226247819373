import { Injectable, Inject } from '@angular/core';
import { FirebaseApp } from 'angularfire2';
import { DataService } from './data.service';

@Injectable()
export class FileUploadService {
  public uploadInProgress: boolean = false;
  public errorMessage: string = '';

  constructor(
    @Inject(FirebaseApp) private firebaseApp: any,
    private data: DataService
  ) {}
  public lock() {
    this.uploadInProgress = true;
  }
  public unlock() {
    this.uploadInProgress = false;
  }

  uploadFile(accordId: string, fileName: string, file: File) {
    if (this.uploadInProgress) {
      // this.errorMessage = 'There is currently an upload already in progress.';
    } else {
      this.errorMessage = '';
    }

    return this.data.accordUser.map(user => {
      const path ='/accord/accords/' + accordId + '/optionFiles/' + user.uid +
        '/' + fileName;
      const storageRef = this.firebaseApp.storage().ref().child(path);
      const meta = {
        contentType: file.type
      };
      const putTask = storageRef.put(file, meta);
      return putTask;
    });
  }
}
