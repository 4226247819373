
import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import "rxjs";

const googleAPIKEY = "AIzaSyBsJjHSwF45JKKApivHuM4zMIADqH3NqFo";


@Injectable()
export class GAPIService {
  constructor(private http: Http) {}

  GetLink(url) {
   
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    let endpoint =
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + googleAPIKEY ;
    return this.http
      .post(
        endpoint,
        { longDynamicLink: "https://join.useaccord.com/?link=" + url,
        "suffix": {
        "option": "SHORT"
        }},
        options
      )
      
      
      .map(response => {
        let obj = response.json();
        let codeArray = obj.shortLink.split(/[\s\/]+/);
        let code = codeArray[codeArray.length - 1];
        return new GetLinkResult(code, obj.shortLink);
      });
  }
}

export class GetLinkResult {
  constructor(public code: string, public link: string) {}
}
