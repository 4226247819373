import { animate, transition, trigger, style, state, query, stagger, sequence
} from "@angular/animations";
import { combineLatest as observableCombineLatest, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AnimationService, AnimationBuilder } from "css-animator";
import { DataService, Accord, AccordChoice, AccordChoiceChooser, AccordOption, AccordState } from "../data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, HostBinding, ElementRef, OnDestroy } from "@angular/core";

@Component({
  selector: "app-room-make-choices-chooser",
  templateUrl: "./room-make-choices-chooser.component.html",
  styleUrls: ["./room-make-choices-chooser.component.css"],
  animations: [
    trigger("RoomMakeChooser", [
      transition(":enter", [
        query(".question-display .make-choices-images, .progress-status, .resultsLink, .previousLink, .nextLink", [
          style({ opacity: 0, transform: "translateY(-100px)" }),
          stagger(500, [
            animate(
              "1s 500ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "translateY(0px)" })
            )
          ])
        ])
      ]),
      transition(':leave', [
        query(".make-choices-images", [
          style({ opacity: 0.5, transform: 'translateX(0%)' }),
          stagger(100, [
            animate('400ms ease-out', style({ transform: 'translateX(-100%)', opacity: 1 }))
          ])
        ])
      ])
  ])
]
})
export class RoomMakeChoicesChooserComponent implements OnInit, OnDestroy {
  @HostBinding("@RoomMakeChooser")
  listTotal = -1;

  public uid: string;
  choicesState = false;
  showPage = false;

  public accord: Accord;
  public id: any = "NONE";
  public percentage: number;
  public option : AccordOption;
  public currentChoice: string;
  public previousChoice: AccordChoiceChooser;
  public choice: AccordChoiceChooser;
  public nextChoice: AccordChoiceChooser;
  public choiceKeys: Array<string>;
  private animator: AnimationBuilder;
  private currentChoiceIndex = 0;
  private choicesLength = 0;
  private choicePushedSubscription: Subscription;
  private userAndParamsSubscription: Subscription;
  constructor(
    private data: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private animationService: AnimationService
  ) {
    this.animator = animationService.builder();
    this.choicePushedSubscription = this.data.choicePushed
      .filter(c => c !== null)
      .subscribe((accordChoice: AccordChoice) => {
        console.log(accordChoice);
        if (this.accord) this.choicePushed(accordChoice);
      });
  }

  ngOnDestroy(): void {
    this.choicePushedSubscription.unsubscribe();
    this.userAndParamsSubscription.unsubscribe();
  }

  ngOnInit() {
    this.data.accord
      .pipe(filter(accord => accord !== null))
      .subscribe(accord => (this.accord = accord));
    this.userAndParamsSubscription = observableCombineLatest(this.data.accordUser,
      this.route.params
    ).subscribe(x => {
      console.log(x);
      const params = x[1];
      this.currentChoice = params["current"];
      this.choiceKeys = Object.keys(this.accord.choices);
      this.choicesLength = this.choiceKeys.length;
      const upperBound = this.choiceKeys.length - 1;
      const lowerBound = 0;
      this.previousChoice = null;
      this.nextChoice = null;
      this.choiceKeys.forEach((value, index) => {
        if (value === this.currentChoice) {
          this.currentChoiceIndex = index;
          this.choice = this.accord.choices[value];
          this.choice.choiceId = value;
          if (index === lowerBound) {
            // first
            this.previousChoice = null;
          }
          if (index >= upperBound) {
            // last
            this.nextChoice = null;
          }
          if (index > lowerBound) {
            const previousChoiceId = this.choiceKeys[index - 1];
            this.previousChoice = this.accord.choices[previousChoiceId];
            this.previousChoice.choiceId = previousChoiceId;
          }
          if (index < upperBound) {
            const nextChoiceId = this.choiceKeys[index + 1];
            this.nextChoice = this.accord.choices[nextChoiceId];
            this.nextChoice.choiceId = nextChoiceId;
          }
        }
      });
    });
    this.data.setAccordStateChooser(this.accord, AccordState.Active);
  }

  private choicePushed(accordChoice: AccordChoice) {
    if (this.nextChoice) {
      this.navChoice(this.nextChoice.choiceId);
    } else {
      this.router.navigate(["/room", this.accord.roomId, "results"]);
    }
  }

  previewResults() {
    this.router.navigate(["/room", this.accord.roomId, "results"]);
  }

  percent() {
    if (this.choicesLength > 0) {
      this.percentage = (this.currentChoiceIndex / this.choicesLength) * 100;
      return this.percentage;
    } else {
      return 0;
    }
  }

  navChoice(key) {
    this.router
    .navigate(["/room", this.accord.roomId, "make-choices", key])
    .then(results => {
      console.log(results);
    });
      this.animator
      .setType("fadeInDown")
      .setDuration(1500)
      .show(this.element.nativeElement)
      .then(result => {
      });
  }

  choose(choice, option : AccordOption) {
    
    option["selected"] = true;
    
        this.data.pushChoice(this.accord, choice, option);
    
          setTimeout (() => {
          option["selected"] = false;
  
          },1000);
    
       }
}
