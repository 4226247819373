import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loaded = false;

  constructor() {}

  ngOnInit() {
    this.loaded = true;
  }
}
