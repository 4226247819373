import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase } from "angularfire2/database";

@Component({
  selector: "app-anonymous-login",
  templateUrl: "./anonymous-login.component.html",
  styleUrls: ["./anonymous-login.component.css"]
})
export class AnonymousLoginComponent implements OnInit {
  authState: any = null;

  constructor(public af: AngularFireAuth, private db: AngularFireDatabase) {}
  ngOnInit() {}
  login() {
    return this.af.auth
      .signInAnonymously()
      .then(user => {
        this.authState = user;
      })
      .catch(error => console.log(error));
  }

  logoff() {
    this.af.auth.signOut();
  }
}
