import { Component, HostBinding, OnInit, Inject } from "@angular/core";
import { trigger, transition, animate, style, state, group, query, stagger } from '@angular/animations';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from "@angular/router";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: "app-email-recover-password",
  templateUrl: "./email-recover-password.component.html",
  styleUrls: ["./email-recover-password.component.css"],
  animations: [
    trigger('RecoverPassAnimation', [
      transition('void => *', [
        query('.forget-password-wrapper', [
          style({opacity: 0, height: '*', transform: 'translateY(-150%)'}),
          stagger(100, [
            animate('1000ms 500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateY(0)" }))
          ])
        ])
      ]),
      transition('* => void', [
        query('.email-form', [
          style({ opacity: 1, height: '*', transform: 'translateX(0)' }),
          stagger(30, [
            animate('0.5s 100ms', style({ transform: 'translateX(-100%)', opacity: 1 }))
          ])
        ])
      ])
    ])
  ]
})
export class EmailRecoverPasswordComponent implements OnInit {

  // @HostBinding('@RecoverPassAnimation')
  public animatePage = false;
  static_value = -1;

  public emailAddress: string = "";
  public emailSent: boolean = false;
  auth: any;
  message: string;
  constructor(
    private af: AngularFireAuth,
    private router: Router,
    ){
    this.auth = firebase.auth();
  }
  ngOnInit() {
  }
  recoverPassword() {
    this.auth
      .sendPasswordResetEmail(this.emailAddress)
      .then(response => {
        console.log("Send reset email.");
        this.message = `If ${
          this.emailAddress
        } is a valid email address, please check your email for a link to reset your password.`;
        this.emailSent = true;
      })
      .catch(error => {
        this.message = error;
        this.emailSent = false;
        console.log(error);
      });
  }

  redirectRoute(){
    this.router.navigateByUrl('/login');
  }
}
