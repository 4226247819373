import { Component, OnInit, Inject } from '@angular/core';
// import {
//   AngularFire,
//   AuthProviders,
//   AuthMethods,
//   FirebaseApp
// } from 'angularfire2';
// import {
//   EmailPasswordCredentials,
//   AuthConfiguration,
//   AngularFireAuth
// } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { DataService } from 'app/data.service';
import { AngularFireAuth } from 'angularfire2/auth';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.css']
})
export class DeleteProfileComponent implements OnInit {
  auth: any;
  authState: any;
  private DEFAULT_ROUTE: string = '/';
  private showConfirmation: boolean = false;
  displayName: string;
  constructor(
    // @Inject(firebase) firebase: any,
    // private data: DataService,
    // private af: AngularFireAuth,
    // private router: Router,
    // private confirmationService: ConfirmationService
  ) {
    // data.displayName.subscribe(name => (this.displayName = name));
    // af.authState.subscribe(authState => {
    //   this.authState = authState;
    // });
  }

  ngOnInit() {}
  deleteUser() {
    // this.confirmationService.confirm({
    //   message: `Are you sure you want to delete the profile for ${
    //     this.displayName
    //   }?`,
    //   key: 'deleteProfile',
    //   accept: () => {
    //     this.authState.auth
    //       .delete()
    //       .then(response => {
    //         console.log('Deleted.');
    //         this.router.navigateByUrl(this.DEFAULT_ROUTE);
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    //   }
    // });
  }
}
