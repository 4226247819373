import { AccordState, DataService } from '../data.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class IsFinishedShouldRedirectToResultsGuard implements CanActivate {
  constructor(private data: DataService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // changed from Observable.combineLatest
    return Observable.zip(this.data.get(next.params.id), this.data.accordUser)
      .first() // don't think I need to hang on to the subscription here
      .map(results => {
        const accord = results[0];
        const user = results[1];
        if (accord.state === AccordState.Finished) {
          this.router.navigate(['/room', next.params.id, 'results']);
          return false;
        }
        return true;
      });
  }
  
}