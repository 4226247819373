<!-- Room Result Wrapper -->
<div class="room-result-filter-wrapper">
  <div class="row question-top">
    <!-- Question Heading-->
    <div class="question-headline col-sm-12 hidden-xs">
      <h3>{{ accordQuestion }}</h3>
    </div>

    <!-- Static Text -->
    <div class="col-sm-12 hidden-xs">
      <h4 class="whose-votes">Whose votes do you want to see?</h4>
    </div>
  </div>
    <!-- Toggle section -->
    <div class="filter-results">
  <div class="row">
    <div class="col-sm-3 col-xs-12">
      <div class="toggle-btns toggle-all">
        <a role="button" (click)="toggleAll($event)">Toggle all</a>
      </div>

      <div class="toggle-btns just-mine" style="margin-top: 15px;">
        <a role="button" (click)="justMine($event)">Just mine</a>
      </div>

      <!-- Tags section -->
      <!-- <div class="tags-Button hidden-xs" style="margin-top: 16px;">
        <label class="tags-label">Tags</label>
        <button class="button-expert">Technical Expert</button>
        <button class="button-focus">Focus Group</button>
        <button class="button-decision">Decision Maker</button>
        <button class="button-sellers">Sellers</button>
      </div> -->

      <!-- End Toggle section -->
    </div>

    <div class="col-sm-9 col-xs-12 member-section">
      <!-- Member section -->
      <div *ngFor="let member of members"
        class="panel custom-input"
        [ngClass]="{ selected: member.selected }"
        (click)="toggleSelected($event, member)"
      >
        <div class="displayName">
          <h4 class="name" *ngIf="member.displayName">
            {{ member.displayName }}
          </h4>
          <h4 class="name" *ngIf="!member.displayName">
            Anonymous Accord Member
          </h4>
        </div>
        <!-- <div class="col-xs-1">
               <div class="pull-right" *ngIf="member.image">
                <img class="img-responsive img-circle" [src]="member.image" />
              </div> 
            </div> -->
      </div>

      <!-- End Member section -->
    </div>
   

    <!-- Action Button -->
    <div class="col-xs-12 apply-button">
      <!-- Apply Button -->
      <a href=""
        [routerLink]="['/room', roomId, 'results']"
        [queryParams]="{ filter: filter }"
        class=""
      >
        Apply
    </a>
    </div>
  </div>
  </div>
</div>
