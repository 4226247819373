import {
  Directive,
  Input,
  EventEmitter,
  OnInit,
  ElementRef,
  Renderer,
  Inject
} from '@angular/core';

@Directive({ selector: '[focus]' })
export class FocusDirective implements OnInit {
  @Input() focus: EventEmitter<boolean>;
  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private renderer: Renderer
  ) {}
  ngOnInit() {
    this.focus.subscribe(event => {
      this.renderer.invokeElementMethod(this.element.nativeElement, 'focus');
    });
  }
}
