
import { animate, transition, trigger,query,stagger, style } from "@angular/animations";
import {filter} from 'rxjs/operators';
import { Accord, DataService } from "../data.service";
import { UtilService } from "../core/util.service";
import { Input, Component, OnInit, AfterViewInit, HostBinding, ChangeDetectionStrategy} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-room-results-filter",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./room-results-filter.component.html",
  styleUrls: ["./room-results-filter.component.css"],
  animations: [
    trigger('roomResultsFilter', [
      transition('void => *', [
        query('.question-top, .whose-votes, .filter-results, .member-section, .apply-button', [
          style({opacity: 0, transform: 'translateY(-300px)'}),
          stagger(200, [
            animate('1s 500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateY(0)" }))
          ])
        ])
      ]),
      transition(":leave", [
        query('.room-result-filter-wrapper', [
        // query('.question-top, .whose-votes, .filter-results, .member-section, .apply-button', [
        style({ transform: "translateX(0%)", opacity: 1 }),
        animate('550ms 300ms', style({ opacity: 1, transform: "translateX(-100%)" }))
      ], { optional: true})
    ])
    ])
    ]
})
export class RoomResultsFilterComponent implements OnInit {
  @HostBinding('@roomResultsFilter')

  public holdData: boolean = false;

  public roomId: string;
  public uid: string;
  public members: Array<any> = new Array<any>();
  public filter: string;
  public accordQuestion: any;
  constructor(
    private route: ActivatedRoute,
    public data: DataService,
    private util: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    this.data.accordUser.pipe(
      filter(u => u != null))
      .subscribe(accordUser => {
        this.uid = accordUser.uid;
        this.route.params.subscribe((params: Params) => {
          this.roomId = params["id"];
          this.data.get(this.roomId);
        });
      });
    this.data.accord.subscribe(accord => {
      this.accordQuestion = accord.question;

      this.members = this.data.membersWithChoices(accord);
      this.members.forEach((value, index) => (value.selected = true));
    });
  }

  toggleSelected(event, member) {
    event.stopPropagation();
    event.preventDefault();
    member.selected = !member.selected;
    this.applyFilter();
  }

  public toggleAll(event) {
    event.stopPropagation();
    const first = this.members[0].selected;
    this.members.forEach((value, index) => {
      value.selected = !first;
    });
    this.applyFilter();
  }

  public justMine(event) {
    event.stopPropagation();
    this.members.forEach((value, index) => {
      value.selected = value.uid === this.uid;
    });
    this.applyFilter();
  }

  public applyFilter() {
    this.filter = "";
    this.members.forEach((value, index) => {
      if (value.selected) {
        if (this.filter) {
          this.filter += "," + (value.id ? value.id : value.uid);
        } else {
          this.filter = value.id ? value.id : value.uid;
        }
      }
    });
  }
}
