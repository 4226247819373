
import {zip as observableZip,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { DataService } from '../data.service';

@Injectable()
export class IsMemberOfRoomGuard implements CanActivate {
  constructor( private data: DataService ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const accordSub = this.data.accord.first(x => x != null);
    const userSub = this.data.accordUser.first(x => x != null);
    // changed from combineLatest
    return Observable.zip(this.data.get(next.params.id), userSub).map(
      result => {
        if (result[0].roomId && result[1].uid) {
          this.data.join(
            result[0].roomId,
            result[1].uid,
            result[1].displayName
          );
          return true;
        }
        return false;
      }
    );
  }
}
