
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {
  DataService,
  AccordUser,
  Accord,
  AccordChoice,
  AccordOption
} from '../data.service';

@Component({
  selector: 'app-room-owner-suggestion-accept-or-reject',
  templateUrl: './room-owner-suggestion-accept-or-reject.component.html',
  styleUrls: ['./room-owner-suggestion-accept-or-reject.component.css']
})
export class RoomOwnerSuggestionAcceptOrRejectComponent implements OnInit {
  canUse: boolean;
  accordUser: AccordUser;
  public accord: Accord;
  @Input() key: string;
  @Input() suggestion: AccordOption;

  constructor(private data: DataService) {}

  ngOnInit() {
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.pipe(filter(a => a !== null)).subscribe(user => {
      this.accordUser = user;
      this.canUse = this.accordUser.uid === this.accord.ownerId;
    });
  }

  approveSuggestion() {
    this.data.pushOption(this.accord, this.suggestion).then(() => {
      this.data.deleteSuggestion(this.accord, this.key);
    });
  }
  removeSuggestion() {
    console.log('Remove this suggestion from suggestions');
    this.data.deleteSuggestion(this.accord, this.key);
    //    this.as.removeSuggestion(this.accord, this.suggestion, this.key).then(_ => this.as.prepareChoices(this.accord));
  }
}
