
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Accord, DataService, AccordUser } from '../data.service';
@Component({
  selector: 'app-room-member-suggestion-list',
  templateUrl: './room-member-suggestion-list.component.html',
  styleUrls: ['./room-member-suggestion-list.component.css']
})
export class RoomMemberSuggestionListComponent implements OnInit {
  accordUser: AccordUser;
  canUse: boolean;
  public memberKeys;
  public accord: Accord;
  public accordMembers;
  public memberResults: any = {};
  constructor(private data: DataService) {}

  ngOnInit() {
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.pipe(filter(user => user !== null)).subscribe(user => {
      this.canUse = true;
      this.accordUser = user;
    });
    this.memberResults = {};
    for (const key in this.accord.members) {
      if (this.accord.members.hasOwnProperty(key)) {
        this.memberResults[key] = {
          name: this.accord.members[key].displayName || 'Anonymous',
          image: this.accord.members[key].images || '',
          id :  this.accord.members[key].uid,
          count: 0
        };
        for (const suggestionKey in this.accord.suggestions) {
          if (this.accord.suggestions.hasOwnProperty(suggestionKey)) {
            if (this.accord.suggestions[suggestionKey].member === key) {
              this.memberResults[key].count++;
            }    
          }
        }
      }
    }

    this.memberKeys = Object.keys(this.memberResults).map((key) => {
      return this.memberResults[key]
    });
    this.accordMembers = this.memberKeys.filter((member) => 
    {
     return  member.id != this.accord.ownerId
    })
  }

}
