import { Component, OnInit, DoCheck, HostListener } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent {
  public innerWidth: any;
  public show: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router , private location: Location) {
  }

}
