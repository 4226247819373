<div class="option-display panel" *ngIf="canUse">
  <div class="row">
    <div class="col-xs-11">
      <form (ngSubmit)="enterOption();" #optionEntryForm="ngForm" >
        <input type="text" [maxlength]="140" class="Enter-suggestion" [(ngModel)]="suggestion" #model="ngModel" (ngModelChange)="suggestion = $event"  (keyup)="showcheck($event)"
          name="suggestion" placeholder="Suggest an idea and press enter" [focus]="myFocusTriggeringEventEmitter"
          required>
        <div [hidden]="model.valid || model.pristine" class="alert alert-danger">Please enter your option to
          continue.
        </div>
        <button type="submit" class="hidden" [disabled]="!formValid">Suggest</button>
      </form>
      <!-- CHECK  -->
      <div class="outer-from-pannel-check1" style="padding-left: 0px;" *ngIf="showCheckIcon1" [@IconsAnimation1]="'in'">
        <a  (click)="enterOption()">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a>
        <button type="submit" class="hidden"></button>
      </div>
      <!-- <div class="col-xs-12 col-md-12" > -->
        <div class="user-img" *ngIf="url">
            <img  [src]="url" class="suggestion-image" />
          <div class="upload-icon-cross" (click)="clearUrl(); false">
            <i class=" fa fa-times" aria-hidden="true"></i>
          </div> 
            </div>
        <!-- </div> -->
    </div>
    <div class="col-xs-1" style="padding-left: 0px; margin-top: 4px;">
      <div class="upload">
        <a href="" *ngIf="!showUpload" (click)="showUpload = !showUpload; false;">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a>
        <a href="" *ngIf="showUpload" (click)="showUpload = !showUpload; false;">
          <i class="fa fa-minus" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="upload image-uploading row" *ngIf="showUpload">
    <div class="col-xs-12">
      <div class="upload-component">
        <app-file-upload (uploadComplete)="uploadComplete($event)" (isUploading)="isUploading($event)"></app-file-upload>
      </div>
    </div>
  </div>

  <!-- <div class="upload-component-wrapper clearfix" *ngIf="url">
    <div class="col-xs-12">
        <i class="image-icon-cross fa fa-times-circle" aria-hidden="true" (click)="clearUrl(); false"></i>
      <img class='upload-image' [src]="url" alt="" /> -->
    <!-- </div> -->
    <!-- <div class="col-xs-1" style="padding-left: 0px;">
      <a href="" class="option-decision option-check" (click)="enterOption(); false;" *ngIf="formValid">
        <i class="fa fa-check"></i>
      </a>
    </div> -->
  <!-- </div> -->
</div>