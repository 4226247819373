<div class="page-wrapper">
  <div class="signupContainer" [ngClass]="{ backBlurSignup: transitionEffect }">
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <span><img class="logo" src="/assets/images/logo-1x.png"/></span>
    <span>
      <p class="text-allign">Get Started Absolutely FREE</p>
    </span>
    <div class="button-allign">
      <button
        class="signupBtn signupBtn--facebook"
        (click)="facebookLogin()"
        *ngIf="!(af.authState | async)?.uid"
      >
        Facebook
      </button>
      <button
        class="signupBtn signupBtn--google"
        (click)="googleLogin()"
        *ngIf="!(af.authState | async)?.uid"
      >
        Google
      </button>
    </div>
    <div class="divider">
      <span>or</span>
    </div>
    <form role="form" class="layout-form" [formGroup]="signupForm" novalidate>
      <div class="input-layout">

        <mat-form-field class="mdCustom animate-name">
          <!-- matInput Your Name -->
          <input
            matInput
            formControlName="firstName"
            placeholder="Your Name"
            autocomplete="off"
            name="firstName"
          />
        </mat-form-field>

        <mat-form-field class="mdCustom animate-email">
          <!-- matInput Email -->
          <input
            matInput
            type="email"
            pattern="[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
            class="validate"
            formControlName="email"
            placeholder="Your Email"
            autocomplete="off"
            name="email"
          />
        </mat-form-field>

        <mat-form-field class="mdCustom animate-password">
          <!-- matInput Password -->
          <input
            matInput
            type="password"
            class="validate"
            formControlName="password"
            placeholder="Your Password"
            autocomplete="off"
            name="password"
          />
        </mat-form-field>

        <div class="wrap">
          <button
            [ngClass]="{ removeWidth: transitionEffect }"
            class="button hvr-float-shadow submitButton"
            (click)="onSubmit()"
          >
            LETS START
          </button>
        </div>
        <span>
          <p class="allign-text">
            Already have an account?
            <a class="login-ref" routerLink="/login">Login!</a>
          </p>
        </span>
        <span>
          <p class="terms">
            By clicking "Lets Start" I agree to Accord's
            <a href="/">Terms of Service.</a>
          </p>
        </span>
      </div>
    </form>
  </div>
</div>
<app-loader *ngIf="transitionEffect"></app-loader>
