<div class="enterQuestions">
  <div class="question-content">
    <div class="enterQuestion" [ngClass]="{'backgroundBlur': (createRoomTransition)}">
      <div class="static-text">
      <h4>Lets get this discussion started.</h4>
      </div>
      <div class="question-entry row" style="padding: 0" class="col-sm-9 col-xs-12">
      <form (keydown)="onSubmit($event)" >
          <div>
            <input class="clear question bottom-border" maxlength="140" name='enter-question' type="text" placeholder="What question are you trying to answer?"
              [(ngModel)]="question" (input)="limitCharacters($event)" >
          </div>
      </form>
    </div>
    <div class="col-sm-3 col-xs-12 transition">
      <button  [ngClass]="{'createRoomTransit': (createRoomTransition)}" class="create-button" (click)="submitQuestion(question)">Create room</button>
    </div>
    </div>
  </div>
</div>
<app-loader *ngIf="createRoomTransition"></app-loader>