<div class="alert" *ngIf="fileUploadError()">{{fileUploadError()}}</div>
<div class="image-container"  [@FileIconsAnimation]="'in'">
  <!-- <h3 class="progress-label" *ngIf="uploadProgress > 0 && uploadProgress < 100">{{uploadProgress}}%</h3> -->
  <div class="row">
    <div class="col-md-12">
        <div *ngIf="!showUploading">
          <span>
          <p-fileUpload #fileInput name="file[]" chooseLabel="Browse" [showUploadButton]="true" 
          [showCancelButton]="true" [customUpload]="true" (uploadHandler)="uploadFile($event)"
          (onRemove)="uploadCleared($event)" [auto]="true" accept="image/*">
           <ng-template pTemplate="content">
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
              <div *ngIf="!showUploading"><p class="file-text">Drag & Drop to Upload or</p></div>
             <div *ngIf="!showUploading"><p class="file-text-p">your file</p></div>
           </ng-template>
           <ng-template let-file pTemplate="file">
             <div class="upload-component-loading col-xs-2" *ngIf="showUploading">
               <img src="/assets/logo.gif" class='img img-responsive' alt="">
             </div>
           </ng-template>
         </p-fileUpload>
         </span>
        </div>
    </div>
  </div>
</div>
