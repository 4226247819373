
import {filter} from 'rxjs/operators';
import { Accord, DataService, AccordOption } from '../data.service';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { trigger, transition, animate, style, query, stagger, state, group } from '@angular/animations';
import { AnimationService, AnimationBuilder } from "css-animator";

@Component({
  selector: 'app-room-owner-option-delete',
  templateUrl: './room-owner-option-delete.component.html',
  styleUrls: ['./room-owner-option-delete.component.css'],
  animations: [
    trigger('optionlistInOut', [
      state('in', style({
        width: 120,
        transform: 'translateX(-50px)', opacity: 1
      })),
      transition('* => *', [
        style({ width: 10, transform: 'translateX(50px)', opacity: 0 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*' }))
        ])
      ]),
      transition('* => *', [
        group([
          animate('900ms ease', style({ opacity: 1, width: 10 }))
        ])
      ])
    ])
  ]
})
export class RoomOwnerOptionDeleteComponent implements OnInit {
  canUse: boolean;
  accord: Accord;
  private animator: AnimationBuilder;
  @Input() currentKey: string;
  constructor(private data: DataService, private animationService: AnimationService, private element: ElementRef) {
    this.animator = animationService.builder();
  }

  ngOnInit() {
    this.data.accord.pipe(filter(a => a !== null)).subscribe(accord => {
      this.accord = accord;
    });
    this.data.accordUser.pipe(filter(a => a !== null)).subscribe(user => {
      this.canUse = user.uid === this.accord.ownerId;
    });
  }

  removeOption() {
    this.animator.setType("fadeInLeft").show(this.element.nativeElement)
    return this.data.deleteOption(this.accord, this.currentKey);
  }
}
