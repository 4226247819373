<div class=" panel customRoomOption" *ngFor="let opt of accord.options| valuesAccordOptions ; let i=index">
  <div class="panel-body">
      <div class="description-header row align-items-center" *ngIf="!opt.descriptionWasClicked"  >
        <div class="col-xs-12">
          <h3 (click)="clickedIfDescriptionIsClickable(opt, opt.description);">{{opt.description}}</h3>
             <div class="user-img" *ngIf="opt.url">
               <img [src]="opt.url" class="show-upload-image" (click)="open(i)" />
        </div>
         </div> 

        <div class="outer-from-pannel-delete1" style="padding-left: 0">
          <div *ngIf="isOwner">
            <app-room-owner-option-delete [currentKey]="opt._key"></app-room-owner-option-delete>
          </div>
        </div>
      </div>

      <div class="description-input" *ngIf="opt.descriptionWasClicked">
        <form (ngSubmit)="updateOption(accord, opt._key, opt, $event);" #optionEntryForm="ngForm" >
          <div class="row">
            <div class="col-xs-12">
              <input #descInput type="text" class="suggestion" (keydown)="onKeydown($event, i, opt)" [(ngModel)]="opt.description"  #model="ngModel"
                (ngModelChange)="opt.description = $event" name="option-description"  placeholder="Type an option and press enter" [focus]="myFocusTriggeringEventEmitter"
                required>
              <div [hidden]="model.valid || model.pristine" class="alert alert-danger">Please enter your option
                to continue.</div>
            </div>
            <div class="showUpload-icon">
              <div *ngIf="isOwner && !opt.url">
                <a href="" *ngIf="!opt.showUpload" (click)="opt.showUpload = !opt.showUpload; false;">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>
                <a href="" *ngIf="opt.showUpload" (click)="opt.showUpload = !opt.showUpload; false;">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <!-- CHECK UNCHECK -->
            <div class="outer-from-pannel-check" style="padding-left: 0px;" [@IconsAnimation]="'in'">
              <a href="" (click)="updateOption(accord, opt._key, opt); false;">
                <i class="fa fa-check" aria-hidden="true"></i>
              </a>
              <button type="submit" class="hidden"></button>
            </div>
            <div class="outer-from-pannel-close" style="padding-left: 0px;" [@IconsAnimation]="'in'">
              <a  (click)="resetOption(i, opt)">
                <i class="fa fa-close" aria-hidden="true"></i>
              </a>
              <button type="submit" class="hidden"></button>
            </div>
          </div>
         <div class="" *ngIf="opt.url">
           <div class="col-xs-12 col-md-12">
              <div class="user-img">
                <img [src]="opt.url" class="show-upload-image" />
                <div class="uploadImage-icon-cross" (click)="clearUrl(accord, opt._key, opt, $event); false">
                  <i class=" fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div> 
        </form> 
        <!-- UPLOADER -->
        <div class="upload image-uploading" *ngIf="opt.showUpload">
          <div class="col-xs-12">
            <div class="upload-component clearfix">
              <app-file-upload (setUrl)="setUrl(accord, opt._key,opt,$event)" (setFileName)="setFileName(opt,$event)"
                (isUploading)="isUploading($event)"></app-file-upload>
            </div>
          </div>
        </div>

      </div>

  </div>
</div>