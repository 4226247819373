// import { FirebaseAuthState } from 'angularfire2/auth';
// import { AngularFire } from 'angularfire2';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AccordService } from '../core/accord.service';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.css']
})
export class PlanSelectionComponent implements OnInit {
  // public user: FirebaseAuthState;

  constructor(public af: AngularFireAuth, public accordService: AccordService) {
  }


  ngOnInit() {
    // this.af.authState.subscribe((authState) => {
    //   this.user = authState;
    // });
  }

  openCheckout() {
    const handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_GDhRZejwcETXcH5r1pFiExa5',
      image: '/assets/images/logo-1x.png',
      locale: 'auto',
      token: (token) => {
        this.accordService.updateStripeInfo(token).then((_) => {
          console.log(token);
        });
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });
    // handler.open({
    //   name: 'Accord Payments',
    //   description: 'Professional Plan',
    //   amount: 2000,
    //   'email': this.user.auth.email,
    //   args: { 'billing_name': this.user.auth.displayName }
    // });
  }

}
