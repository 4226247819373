<div *ngIf="mode == 'guest'" class="display-wrapper">
  <div class="enter-displayname" [ngClass]="{ backBlur: loginSubmitTransition }">
    <h4 *ngIf="needDisplayName">
      Please enter a display name before continuing...
    </h4>
    <form (submit)="setDisplayProfile()">
      <div class="row">
        <div class="col-sm-9 col-xs-11">
          <input
            class="display-name"
            [maxlength]="30"
            name="displayName"
            type="text"
            placeholder="Display Name"
            [(ngModel)]="displayName"
          />
        </div>
        <div class="col-sm-3 col-xs-6">
          <button type="submit" class="display-name-button">
            Set Display Name
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="login-page-wrapper" *ngIf="mode == 'login'">
  <div class="loginContainer" [ngClass]="{ backBlur: loginSubmitTransition }">
    <span
      ><img class="logo" src="/assets/images/logo-1x.png" routerLink="/"
    /></span>
    <span>
      <p class="text-allign">Get Started Absolutely FREE</p>
    </span>
    <div class="button-allign">
      <button
        class="loginBtn loginBtn--facebook"
        (click)="facebookLogin()"
        *ngIf="!(af.authState | async)?.uid"
      >
        Facebook
      </button>
      <button
        class="loginBtn loginBtn--google"
        (click)="googleLogin()"
        *ngIf="!(af.authState | async)?.uid"
      >
        Google
      </button>
      <app-anonymous-login class="anonymous-login"
        *ngIf="returnUrl && returnUrl.length > 5"
      ></app-anonymous-login>
    </div>
    <div class="divider">
      <span>or</span>
    </div>
    <form role="form" class="layout-form">
      <div class="input-layout">
        <mat-form-field class="mdCustom email-animate">
          <!-- matInput Email -->
          <input
            matInput
            placeholder="Your Email"
            name="email"
            [(ngModel)]="email"
            class="validate"
          />
        </mat-form-field>

        <mat-form-field class="mdCustom password-animate">
          <!-- matInput Email -->
          <input
            matInput
            type="password"
            autocomplete="off"
            placeholder="Your Password"
            name="password"
            [(ngModel)]="password"
            class="validate"
          />
        </mat-form-field>
        <div class="wrap">
          <button
            [ngClass]="{ loginTransit: loginSubmitTransition }"
            class="button hvr-float-shadow submitButton"
            (click)="login()"
            value="Submit"
          >
            LOGIN
          </button>
        </div>
        <span>
          <p class="allign-text forgot-password">
            <a routerLink="/recover-password">Forgot your password?</a>
          </p>
        </span>
        <span>
          <p class="terms">
            No account yet? <a routerLink="/signup">Create!</a>
          </p>
        </span>
      </div>
    </form>
  </div>
</div>
<app-loader *ngIf="loginSubmitTransition"></app-loader>
