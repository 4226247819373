import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { animate, transition, trigger, style } from "@angular/animations";
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Router } from "@angular/router";
import { DataService } from "app/data.service";
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],

  animations: [
    trigger("expandAnimation", [
      transition(":enter", [
        style({ transform: "translateY(-30%)", opacity: 0 }),
        animate("500ms", style({ transform: "translateY(0%)", opacity: 1 }))
      ])
    ])
  ]
})
export class NavbarComponent implements OnInit {
  toggleMenu = false;
  navBarAnimation = false;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private data: DataService,
    private toastr: ToastrManager
  ) {}

  redirectRoute() {
    this.router.navigateByUrl("/enter-question");
  }
  redirectToProfile() {
    this.router.navigateByUrl("/profile");
  }

  onToggleMenu() {
    this.toggleMenu == true;
  }
  logout() {
    this.afAuth.auth.signOut().then(result => {
      this.toastr.successToastr('Success!', 'Logged out!',{
        toastTimeout: '700',maxShown: (1),position: ('bottom-right') 
      });
      this.router.navigateByUrl("/login");
      
    });
  }
  
  ngOnInit() {
    this.navBarAnimation = true;
  }
}
