
import {filter} from 'rxjs/operators';
import { Component, OnInit, HostBinding } from "@angular/core";
import { animate, transition, trigger,query,stagger, style } from "@angular/animations";

import { DataService, Accord } from "../data.service";
import { Params, ActivatedRoute } from "@angular/router";
import { UtilService } from "../core/util.service";
import { LightboxConfig, Lightbox } from "ngx-lightbox";

@Component({
  selector: "app-room-results-graph",
  templateUrl: "./room-results-graph.component.html",
  styleUrls: ["./room-results-graph.component.css"],
  animations: [
    trigger('resultsGraphAnimation', [
      transition('void => *', [
        query('.d-flex-custom', [
          style({opacity: 0, transform: 'translateY(-300px)'}),
          stagger(200, [
            animate('1s 500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateY(0)" }))
          ])
        ])
      ]),
      transition(":leave", [
        query('.d-flex-custom', [
        style({ transform: "translateX(0%)", opacity: 1 }),
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: "translateX(-100%)" }))
      ],{ optional: true })
    ])
    ])
]
})
export class RoomResultsGraphComponent implements OnInit {
  // @HostBinding('@resultsGraphAnimation')
  public animatePage = false;
  static_value = -1;

  statusAnimation = false;
  roomId: any;
  public accord: Accord;
  results: Array<any>;
  maxWins: number = 0;
  hasImages: boolean = false;
  maxBarResult: boolean = false;
  private _albums = [];
  src: any;
  private filter: Array<string>;
  private mapping = {};

  constructor(
    private util: UtilService,
    private data: DataService,
    private route: ActivatedRoute,
    private _lightbox: Lightbox,
    private _lighboxConfig: LightboxConfig
  ) {
    _lighboxConfig.positionFromTop = 80;
    _lighboxConfig.disableScrolling = true;
    _lighboxConfig.showImageNumberLabel = false;
  }

  percent(wins) {
    this.maxBarResult = true;
    if (this.maxWins > 0) {
      return (wins / this.maxWins) * 100;
    } else {
      return 0;
    }
  }

  getMaximumVotes(wins) {
    if (this.maxWins > wins) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.statusAnimation = true;
    this.data.accord$.subscribe(accord => (this.accord = accord));
    this.data.accordUser.pipe(
      filter(u => u != null))
      .subscribe(accordUser => {
        this.route.params.subscribe((params: Params) => {
          this.roomId = params["id"];
          this.route.queryParams.subscribe((queryParams: Params) => {
            if (queryParams.filter) {
              this.filter = queryParams.filter.split(",");
            } else {
              this.filter = null;
            }
            this.buildGraph();
          });
        });
      });
  }
  buildGraph() {
    const choices = this.util.objectToArray(this.accord.choices);
    const options = this.util.objectToArray(this.accord.options);
    let tempResults = {};
    options.forEach(option => {
      if (!tempResults.hasOwnProperty(option._key)) {
        if (option.url && !this.hasImages) this.hasImages = true;

        tempResults[option._key] = {
          wins: 0,
          description: option.description,
          url: option.url
        };
      }
    });
    choices.forEach(choice => {
      if (choice.members) {
        const choiceMembers = this.util.objectToArray(choice.members);
        choiceMembers
          .filter(filterableMember =>
            this.filter && this.filter.length > 0
              ? this.filter.indexOf(filterableMember._key) > -1
              : true
          )
          .forEach(choiceMember => {
            if (choiceMember.option && choiceMember.option._key) {
              if (tempResults.hasOwnProperty(choiceMember.option._key)) {
                tempResults[choiceMember.option._key].wins++;
              }
            }
          });
      }
    });
    this.results = this.util.objectToArray(tempResults);

    this._albums = [];
    this.mapping = {};
    
    this.results = this.results.sort((n1, n2) => {
      if (n1.wins > n2.wins) {
        return -1;
      }
      if (n1.wins < n2.wins) {
        return 1;
      }
      return 0;
    });

    this.results.forEach((result, i) => {
      let option = result;
      if (!option.url) {
      } else {
        this.mapping[i]= Object.keys(this.mapping).length
        this.src = option.url;
        const caption = "Image " + i + "";
        const thumb = option.url;
        const album = {
          src: this.src,
          caption: caption,
          thumb: thumb
        };
        this._albums.push(album);
      }
      if (result.wins > this.maxWins) {
        this.maxWins = result.wins;
      }
    });
  }
  
  open(index: number) {
    this._lightbox.open(this._albums, this.mapping[index], { showImageNumberLabel: false });
  }
}

export class RoomResult {
  id: string;
  description: string;
  wins: number;
}
