
import { filter } from 'rxjs/operators';
import { AccordOption } from "../core/accord.service";
import { Accord, DataService } from "../data.service";
import { LightboxConfig, Lightbox } from "ngx-lightbox";
import { trigger, transition, animate, style, query, stagger, state, group } 
from '@angular/animations';
import { Component, OnInit, ViewChildren, AfterViewInit, HostBinding, EventEmitter }
from "@angular/core";
import { UtilService } from "../core/util.service";

@Component({
  selector: "app-room-option-list",
  templateUrl: "./room-option-list.component.html",
  styleUrls: ["./room-option-list.component.css"],
  animations: [
    trigger('IconsAnimation', [
      state('in', style({
        transform: 'translateX(-50)', opacity: 1
      })),
      transition(':enter', [
        style({ width: '*', transform: 'translateX(-50px)', opacity: 1 }),
        group([
          animate('900ms ease', style({ opacity: 1, width: '*', transform: 'translateX(0px)' }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('1s ease', style({ opacity: 0, width: '*' }))
        ])
      ])
    ])
  ]
})
export class RoomOptionListComponent implements OnInit{
  

  @ViewChildren("descInput") descInput: any;
  isOwner: boolean;
  accord: Accord;
  public editingValue;
  private _albums = [];
  private mapping = {};

  public myFocusTriggeringEventEmitter = new EventEmitter<boolean>();
 

  constructor(
    private _lightbox: Lightbox,
    private _lighboxConfig: LightboxConfig,
    private util: UtilService,
    private data: DataService
  ) {
    _lighboxConfig.positionFromTop = 80;
    _lighboxConfig.disableScrolling = true;
    _lighboxConfig.showImageNumberLabel = false;
  }

  ngOnInit() {
    this.data.accord$.subscribe(accord => {
      this.accord = accord;
      this._albums = [];
      this.mapping = {};
      if (this.accord.options) {
        Object.keys(this.accord.options).forEach((opt, i) => {
          let option = this.accord.options[opt];
          if (!option.url) {
          } else {
            this.mapping[i]= Object.keys(this.mapping).length
            const src = option.url;
            const caption = "Image " + i + "";
            const thumb = option.url;
            const album = {
              src: src,
              caption: caption,
              thumb: thumb
            };
            this._albums.push(album);
          }
        });
      }
    });

    this.data.accordUser.pipe(
      filter(a => a !== null))
      .subscribe(user => {
        this.isOwner = user.uid === this.accord.ownerId;
      });
  }



  open(index: number) {
    this._lightbox.open(this._albums, this.mapping[index]);
    
  }

  ngAfterViewInit(): void {
    // set focus when it comes into view.
    if (this.descInput) {
      this.descInput.changes.subscribe(changeEvent => {
        if (changeEvent.first && changeEvent.first.nativeElement) {
          changeEvent.first.nativeElement.focus();
        }
      });
    }
  }

  clickedIfDescriptionIsClickable( opt: { descriptionWasClicked: boolean },description ) {
    if (this.isOwner) {
      for (let key in this.accord.options) {
        let selectedKey = this.accord.options;
        selectedKey[key].descriptionWasClicked = null;
      }
      opt.descriptionWasClicked = true;
      this.editingValue = description;
    }
  }

  setUrl(accord: Accord, key: string, opt: AccordOption, event) {
    opt.url = event;
    opt["showUpload"] = null;
    this.data.setOption(accord, key, opt).then(() => {
      return this.data.rebuildChoices(accord, true);
    });
  }

  clearUrl(accord: Accord, key: string, opt: AccordOption, event) {
    opt.url = "";
    this.data.setOption(accord, key, opt);
  }

  onKeydown(event, index, opt) {
    if (event.key === "Escape") {
      this.resetOption(index, opt);
    }
  }

  updateOption(accord: Accord, key: string, opt: AccordOption) {
    opt["descriptionWasClicked"] = null;
    this.data.setOption(accord, key, opt).then(() => {
      this.data.rebuildChoices(accord, true);
    });
  }

  setFileName(opt: AccordOption, event) {
  }

  isUploading(event) {}

  resetOption(index, opt: { descriptionWasClicked: boolean }) {
    this.accord.options[
      Object.keys(this.accord.options)[index]
    ].description = this.editingValue;
    opt.descriptionWasClicked = false;
  }
}
