import { Component, OnInit, ViewEncapsulation, HostBinding } from "@angular/core";
import { animate, transition, trigger, style, query, stagger, state,group } from "@angular/animations";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { Router } from "@angular/router";
import { LocalStorageService } from "angular-2-local-storage";
import { DataService } from "../data.service";
import { ToastrManager } from "ng6-toastr-notifications";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

@Component({
  selector: "app-email-login",
  templateUrl: "./email-login.component.html",
  styleUrls: ["./email-login.component.css"],
  animations: [
    trigger("EmailLoginAnimations", [
      transition("* => *", [
        query(".logo, .text-allign, .button-allign, .divider, .email-animate, .password-animate, .submitButton, .forgot-password, .terms ", [
          style({ opacity: 0, transform: "translateY(-100px)" }),
          stagger(250, [
            animate(
              "800ms 1000ms cubic-bezier(.41, .43, .5, .56)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ],{ optional: true })
      ]),
      transition(':leave', [
        query(".logo, .text-allign, .button-allign, .divider", [
          style({ opacity: 1, transform: 'translateX(0%)' }),
          stagger(100, [
            animate('500ms 1000ms ease-out', style({ transform: 'translateX(-100%)', opacity: 1 }))
          ])
        ])
      ])
    ]),
  ]
})
export class EmailLoginComponent implements OnInit {

  @HostBinding("@EmailLoginAnimations")
  public loginSubmitTransition: boolean = false;
  public canLink: boolean;
  public email: string;
  public facebookLoginEmail: string;
  private DEFAULT_ROUTE = "/";
  public password: string;
  public message: string;
  public returnUrl: string;
  public needDisplayName: boolean = false;
  public displayName: any;
  // public auth: FirebaseAuthState;
  public userGoogle: any = new firebase.auth.GoogleAuthProvider();
  public userFacebook = new firebase.auth.FacebookAuthProvider();
  auth: any = null;
  public mode: string;
  authState: any = null;
  constructor(
    public af: AngularFireAuth,
    private router: Router,
    private localStorage: LocalStorageService,
    private data: DataService,
    private toastr: ToastrManager,
    private db: AngularFireDatabase
  ) {
    this.mode = "nothing";
    this.loginSubmitTransition = true;
    af.authState.subscribe(authState => {
      this.auth = authState;
      this.returnUrl = this.localStorage.get<string>("routerState.Url");
      this.go();
    });
  }

  ngOnInit() {
    this.getFacebookuser();
    this.getGoogleUser();
  }

  go() {
    this.mode = "login";
    if (this.auth && this.returnUrl) {
      if (this.auth.displayName) {
        this.localStorage.remove("routerState.Url");
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.needDisplayName = true;
        this.mode = "guest";
      }
    } else if (this.auth) {
      this.router.navigateByUrl(this.DEFAULT_ROUTE);
    }
    this.loginSubmitTransition = false;
  }

  setDisplayProfile() {
    this.data.setDisplayProfile(this.displayName).then(() => {
        this.mode = "guest";
        return (this.needDisplayName = false);
      }).then(() => {
          this.localStorage.remove("routerState.Url");
          this.router.navigateByUrl(this.returnUrl);
          this.loginSubmitTransition = true;
      });
  }

  login() {
    if( this.email =='' || this.email == undefined || this.password=='' || this.password== undefined  ) {
      this.toastr.errorToastr("Error!", "Please Enter Email or Password!", {
        toastTimeout: '700',maxShown: (1),position: ('bottom-right')
      });      
    } else {
      this.loginSubmitTransition = true;
      return this.af.auth
      .signInWithEmailAndPassword(this.email, this.password)
      .then(user => {
        this.authState = user;
        this.toastr.successToastr("Success!", "Logged In!", {
          toastTimeout: '700',maxShown: (1),position: ('bottom-right')
        });
      })
      .catch(error => {
        this.loginSubmitTransition = false;
        this.toastr.errorToastr("Error!", "Incorrect Email or Password!", {
          toastTimeout: '700',maxShown: (1),position: ('bottom-right')
        });
      });
    }

  }

  googleLogin() {
      firebase.auth().signInWithPopup(this.userGoogle).then(result => {
        
        var token = result.credential;
        var user = result.user;
        this.authState = user;
      }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  facebookLogin() {
    firebase.auth().signInWithPopup(this.userFacebook).then(function(result) {
        var token = result.credential;
        var user = result.user;
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  getFacebookuser() {
    firebase.auth().getRedirectResult().then(function(result) {
        if (result.credential) {
          var token = result.credential;
        }
        var user = result.user;
      }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  getGoogleUser() {
    firebase.auth().getRedirectResult().then(result => {
        if (result.credential) {
          var token = result.credential;
        }
        var user = result.user;
      }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
      });
  }

  redirectRoute(){
    this.router.navigateByUrl('/recover-password');
  }
}
